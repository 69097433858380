<template>
  <right-wing-item
    :rightWingTitle="`${$t('studio.prj_prod.execution_install_new_guide_pc_title')}`"
    class="mb-24"
  >
    <div v-show="isShowPcExecutionName">
      <p class="text-on-surface-elevation-2 font-medium mt-8">
        {{ $t('studio.prj_prod.execution_exe_opt_enabled_guide_opt_name') }}
      </p>
      <safe-html :html="$t('studio.prj_prod.execution_install_new_guide_pc_2_opt_name')" tag="p" />
      <div class="mt-8">
        <a :href="guideImages5Src" target="_blank">
          <img :src="guideImages5Src" alt="" class="w-full block" />
        </a>
      </div>
    </div>
    <s-text as="span" :role="stextRole" :class="stextClass">
      {{ $t('studio.prj_prod.this_prod.execution_setting_option_executing_file_msg') }}
      <ul :class="itemContainerCssClass">
        <safe-html
          :html="$t('studio.prj_prod.execution_install_new_guide_pc_sub_des1')"
          :class="itemCssClass"
          tag="li"
        />
        <safe-html
          :html="$t('studio.prj_prod.execution_install_new_guide_pc_sub_des2')"
          :class="itemCssClass"
          tag="li"
        />
        <safe-html
          :html="$t('studio.prj_prod.execution_install_new_guide_pc_sub_des3')"
          :class="itemCssClass"
          tag="li"
        />
      </ul>
    </s-text>
    <s-text as="span" :role="stextRole" :class="stextClass">
      {{ $t('studio.prj_prod.this_prod.execution_setting_option_final_run_file') }}
      <ul :class="itemContainerCssClass">
        <safe-html
          :html="$t('studio.prj_prod.execution_install_new_guide_pc_sub1_des1')"
          :class="itemCssClass"
          tag="li"
        />
        <safe-html
          :html="$t('studio.prj_prod.execution_install_new_guide_pc_sub1_des2')"
          :class="itemCssClass"
          tag="li"
        />
      </ul>
    </s-text>
    <s-text as="span" :role="stextRole" :class="stextClass">
      {{ $t('studio.prj_prod.this_prod.execution_setting_option_parameter_msg') }}
      <ul :class="itemContainerCssClass">
        <safe-html
          :html="$t('studio.prj_prod.execution_install_new_guide_pc_sub2_des1')"
          :class="itemCssClass"
          tag="li"
        />
      </ul>
    </s-text>
    <s-text as="span" :role="stextRole" :class="stextClass">
      {{ $t('studio.prj_prod.execution_exe_opt_enabled_guide4_3_1') }}
      <ul :class="itemContainerCssClass">
        <safe-html
          :html="$t('studio.prj_prod.execution_install_new_guide_pc_sub3_des1')"
          :class="itemCssClass"
          tag="li"
        />
      </ul>
    </s-text>
    <s-text as="span" :role="stextRole" :class="stextClass">
      {{ $t('studio.prj_prod.execution_exe_opt_enabled_guide4_4_1') }}
      <ul :class="itemContainerCssClass">
        <safe-html
          :html="$t('studio.prj_prod.execution_install_new_guide_pc_sub4_des1')"
          :class="itemCssClass"
          tag="li"
        />
      </ul>
    </s-text>
    <s-text as="span" :role="stextRole" :class="stextClass">
      {{ $t('studio.prj_prod.execution_exe_opt_enabled_guide4_5_1') }}
      <ul :class="itemContainerCssClass">
        <safe-html
          :html="$t('studio.prj_prod.execution_install_new_guide_pc_sub5_des1')"
          :class="itemCssClass"
          tag="li"
        />
        <safe-html
          :html="$t('studio.prj_prod.execution_install_new_guide_pc_sub5_des2')"
          :class="itemCssClass"
          tag="li"
        />
      </ul>
    </s-text>
  </right-wing-item>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import * as GuideImages5 from '@/assets/images/run-set-up/guide-flatform-pc-sub';
import RightWingItem from '@/components/app/right-wing/item.vue';
import SafeHtml from '@/components/common/safe-html.vue';

const { locale } = useI18n();

defineProps<{ isShowPcExecutionName: boolean }>();

const itemCssClass =
  'relative pl-16 before:absolute before:top-[.9rem] before:left-4 before:w-4 before:h-4 before:rounded-full before:bg-[currentColor]';
const itemContainerCssClass = 'text-on-surface-elevation-3 font-regular';
const stextRole = 'text4';
const stextClass = 'block font-medium mt-8 text-on-surface-elevation-2';
const guideImages5 = ref<Record<string, string>>(GuideImages5);

const guideImages5Src = computed(
  () => guideImages5.value[`Guide_${locale.value.replace('-', '_')}`]
);
</script>
