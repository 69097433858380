<template>
  <div v-if="showContent" class="max-w-[96rem] mx-auto">
    <launch-setting-review-release
      v-if="showReviewingScreen"
      :installationPathAndPcClientSettings="installationPathAndPcClientSettingsRef"
      :runOptions="runOptionsRef"
    />
    <template v-else>
      <div v-if="!isRegistered">
        <s-button
          variant="primary"
          size="md"
          class="!min-w-[11.6rem] absolute right-0 top-[-80px]"
          @click="handleClickButton"
        >
          {{ $t('studio.prj_prod.this_prod.execution_register_btn') }}
        </s-button>
        <launch-setting-empty />
      </div>
      <div v-else class="flex gap-24">
        <launch-dlc-content-setting-form
          v-if="typeGame === LINKAGE_PRODUCT_TYPE.DLC || typeGame === LINKAGE_PRODUCT_TYPE.CONTENTS"
          :typeGame="typeGame"
          :installationPathAndPcClientSettings="installationPathAndPcClientSettingsRef"
          :gameId="gameId"
          :releaseType="launchSettingsStatusRef?.releaseType || ''"
          :planStatus="launchSettingsStatusRef?.planStatus || ''"
          :verifyStatus="launchSettingsStatusRef?.verifyStatus || ProductVerifyStatus.NONE"
        />
        <launch-game-demo-ultility-setting-form
          v-else
          :installationPathAndPcClientSettings="installationPathAndPcClientSettingsRef"
          :runOptions="runOptionsRef || []"
          :gameId="gameId"
          :typeGame="typeGame"
          :releaseType="launchSettingsStatusRef?.releaseType || ''"
          :planStatus="launchSettingsStatusRef?.planStatus || ''"
          :verifyStatus="launchSettingsStatusRef?.verifyStatus || ProductVerifyStatus.NONE"
          @onShowExecutionNameForSamePlatform="(value: boolean) => isShowPcExecutionName = value"
          @onUpdatePlatformGuide="(platforms: string[]) => onUpdatePlatformGuide(platforms)"
        />
        <template v-if="isRegistered">
          <launch-setting-guide-dlc
            v-if="
              typeGame === LINKAGE_PRODUCT_TYPE.DLC || typeGame === LINKAGE_PRODUCT_TYPE.CONTENTS
            "
          />
          <launch-setting-guide
            v-else
            :typeGame="typeGame || ''"
            :guide="guidePart"
            :platFormsGuide="platFormsGuide"
            :isShowPcExecutionName="isShowPcExecutionName"
          />
        </template>
      </div>
    </template>
  </div>
</template>
<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { computed, ref, shallowRef } from 'vue';

import { definePageMeta } from '@/.nuxt/imports';
import {
  fetchInstallPathAndPcClientSettingsApi,
  fetchRunOptionsApi
} from '@/apis/launch-settings.api';
import LaunchDlcContentSettingForm from '@/components/launch-setting/launch-dlc-content-setting-form.vue';
import LaunchGameDemoUltilitySettingForm from '@/components/launch-setting/launch-game-demo-ultility-setting-form.vue';
import LaunchSettingEmpty from '@/components/launch-setting/launch-setting-empty.vue';
import LaunchSettingGuide from '@/components/launch-setting/launch-setting-guide.vue';
import LaunchSettingReviewRelease from '@/components/launch-setting/launch-setting-review-release.vue';
import { LAUNCH_SETTING_GUIDE_PART } from '@/constants/launch-setting.const';
import { PRODUCT_TITLE } from '@/constants/page.const';
import { ROLE_ID } from '@/constants/permission.const';
import {
  LINKAGE_PRODUCT_TYPE,
  PRODUCT_FEAT_NAME,
  PRODUCT_TYPE_DETAIL
} from '@/constants/products.const';
import { PRODUCT_LAUNCH_TYPE } from '@/constants/release-plan.const';
import { ProductVerifyStatus } from '@/enums/common.enum';
import useProductStore from '@/stores/product.store';
import type { RunOptionGroup } from '@/types/launch-settings/launch-settings.type';
import type {
  InstallPathAndPcClientSettingsResponse,
  LaunchSettingsStatusResponse
} from '@/types/launch-settings/launch-settings-response.type';

definePageMeta({
  middleware: 'handle-product-data-middleware',
  roleAuthorityId: ROLE_ID.PRODUCT_RUN_OPTION,
  productFeat: PRODUCT_FEAT_NAME.LAUNCH_SETTING,
  pageTitle: PRODUCT_TITLE.LAUNCH_SETTING,
  showProductKey: true
});

const productStore = useProductStore();
const { product, currentProductDetailType, isPageBuild } = storeToRefs(productStore);

const typeGameMapperComputed = computed(() => {
  switch (currentProductDetailType.value) {
    case PRODUCT_TYPE_DETAIL.CONTENT_BASIC:
      return LINKAGE_PRODUCT_TYPE.CONTENTS;
    case PRODUCT_TYPE_DETAIL.UTILITY_DEMO:
    case PRODUCT_TYPE_DETAIL.CONTENT_DEMO:
    case PRODUCT_TYPE_DETAIL.GAME_DEMO:
      return LINKAGE_PRODUCT_TYPE.DEMO;
    case PRODUCT_TYPE_DETAIL.GAME_BASIC:
      return LINKAGE_PRODUCT_TYPE.GAME;
    case PRODUCT_TYPE_DETAIL.UTILITY_BASIC:
      return LINKAGE_PRODUCT_TYPE.UTILITY;
    case PRODUCT_TYPE_DETAIL.GAME_DLC:
    case PRODUCT_TYPE_DETAIL.UTILITY_DLC:
      return LINKAGE_PRODUCT_TYPE.DLC;
    default:
      return '';
  }
});

const typeGame = typeGameMapperComputed.value;
const isRegistered = ref<boolean>(false);
const installationPathAndPcClientSettingsRef = shallowRef<InstallPathAndPcClientSettingsResponse>();
const runOptionsRef = ref<Array<RunOptionGroup>>();
const launchSettingsStatusRef = shallowRef<LaunchSettingsStatusResponse>();
const showContent = ref<boolean>(false);
const platFormsGuide = ref<Array<string>>([]);
const gameId = product.value?.gameId || '';
const isEditMode = computed(
  () =>
    !!installationPathAndPcClientSettingsRef.value?.id ||
    (Array.isArray(runOptionsRef.value) && runOptionsRef.value?.length > 0)
);

const showReviewingScreen = ref<boolean>(false);

const guidePart = ref<string>(LAUNCH_SETTING_GUIDE_PART.PLATFORM);
const isShowPcExecutionName = ref<boolean>(false);

function onUpdatePlatformGuide(platforms: string[]) {
  platFormsGuide.value = platforms;
}

const handleClickButton = () => {
  isRegistered.value = true;
};
const init = async () => {
  const apis: Array<Promise<unknown>> = [
    fetchInstallPathAndPcClientSettingsApi(gameId)
  ];

  const launchSettingsStatus: LaunchSettingsStatusResponse = { releaseType: '', verifyStatus: ProductVerifyStatus.NONE, planStatus: '' };

  launchSettingsStatus.releaseType = isPageBuild.value ? PRODUCT_LAUNCH_TYPE.PAGE_BUILD : PRODUCT_LAUNCH_TYPE.PAGE;
  launchSettingsStatus.verifyStatus = product.value?.verifyStatus || ProductVerifyStatus.NONE;
  launchSettingsStatus.planStatus = product.value?.planStatus || '';

  launchSettingsStatusRef.value = launchSettingsStatus;
  if (typeGame !== LINKAGE_PRODUCT_TYPE.DLC && typeGame !== LINKAGE_PRODUCT_TYPE.CONTENTS) {
    apis.push(fetchRunOptionsApi(gameId));
  } else {
    isRegistered.value = true;
  }
  const results = await Promise.all(
    apis
  );
  installationPathAndPcClientSettingsRef.value = results[0] as InstallPathAndPcClientSettingsResponse | undefined;
  runOptionsRef.value = results[1] as Array<RunOptionGroup> | undefined;
  if (isEditMode.value) {
    if (launchSettingsStatus?.verifyStatus === ProductVerifyStatus.START) {
      showReviewingScreen.value = true;
    } else if (
      typeGame === LINKAGE_PRODUCT_TYPE.DLC ||
      typeGame === LINKAGE_PRODUCT_TYPE.CONTENTS
    ) {
      guidePart.value = LAUNCH_SETTING_GUIDE_PART.INSTALLATION_FOLDER;
    } else {
      isRegistered.value = true;
    }
  }
  showContent.value = true;
};
init();
</script>
