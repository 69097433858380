<template>
  <div class="flex flex-col gap-12">
    <div class="flex items-start gap-8">
      <div class="w-[18rem] shrink-0 mt-[.5rem]">
        <st-form-title
          :formTitle="$t('studio.prj_prod.this_prod.execution_setting_option_parameter_msg')"
          subTitle
        />
      </div>
      <div class="flex-1">
        <input-text
          :countable="false"
          :disabled="!isProductWritable || isDisabledAll"
          size="sm"
          :name="`launchOptions[${index}].argument`"
          :placeholder="
            $t('studio.prj_prod.this_prod.execution_setting_option_parameter_place_holder')
          "
          :rules="getRules()"
        />
      </div>
    </div>
    <div class="flex items-start gap-8">
      <div class="w-[18rem] shrink-0 mt-[.5rem]">
        <st-form-title
          :formTitle="
            $t('studio.prj_prod.this_prod.execution_setting_option_working_directory_msg')
          "
          subTitle
        />
      </div>
      <div class="flex-1">
        <input-text
          :countable="false"
          :disabled="!isProductWritable || isDisabledAll"
          :rules="getRules()"
          size="sm"
          :name="`launchOptions[${index}].workingDirectory`"
          :placeholder="
            $t('studio.prj_prod.this_prod.execution_setting_option_working_directory_place_holder')
          "
        />
      </div>
    </div>
    <div class="flex items-start gap-8">
      <div class="w-[18rem] shrink-0 mt-[.5rem]">
        <st-form-title
          :formTitle="$t('studio.prj_prod.this_prod.execution_setting_option_process_name_msg')"
          subTitle
        />
      </div>
      <div class="flex-1">
        <input-text
          :disabled="!isProductWritable || isDisabledAll"
          :countable="false"
          :rules="getRules()"
          size="sm"
          :name="`launchOptions[${index}].processFile`"
          :placeholder="
            $t('studio.prj_prod.this_prod.execution_setting_option_process_name_place_holder')
          "
        />
      </div>
    </div>
  </div>
  <st-form-title
    :formTitle="$t('studio.prj_prod.execution_setting_option_requirements_title')"
    subTitle
    class="mt-12"
  />
  <div class="mt-8 flex flex-col gap-8">
    <div
      v-for="{
        items,
        type,
        title,
        displayAs,
        isDisabled,
        isMultiple,
        placeHolder
      } of requiredPrograms"
      :key="type"
      class="flex gap-8 items-center"
    >
      <p
        class="flex items-center text-sm leading-md text-on-surface-elevation-2 font-regular w-[18rem] shrink-0"
      >
        <i class="mx-8 shrink-0 w-4 h-4 rounded-full bg-placeholder"></i>
        {{ title }}
      </p>
      <div v-if="displayAs === REQUIRED_PROGRAM_DISPLAY_AS.TEXT_INPUT" class="flex-1 relative">
        <input-text
          :disabled="isDisabled || !isProductWritable || isDisabledAll"
          :name="`launchOptions[${index}].${type}`"
          :placeholder="placeHolder"
          size="sm"
        />
      </div>
      <div
        v-else-if="displayAs === REQUIRED_PROGRAM_DISPLAY_AS.DROP_BOX && isMultiple"
        class="flex-1 relative"
        :class="{ 'z-[2]': isListMore }"
      >
        <launch-setting-dropdown
          :name="`launchOptions[${index}].${type}`"
          :disabled="!isProductWritable || isDisabled || isDisabledAll"
          :items="items || []"
          :maxOptionCanSelect="4"
          :placeholder="t('studio.prj_prod.execution_setting_option_requirements_cpp_place_holder')"
        />
      </div>
      <div v-else class="flex-1 relative">
        <dropdown
          :name="`launchOptions[${index}].${type}`"
          :options="items || []"
          :disabled="!isProductWritable || isDisabled || isDisabledAll"
          :placeholder="
            $t('studio.prj_prod.execution_setting_option_requirements_cpp_place_holder')
          "
          :dropdownProps="{
            size: 'sm',
            variant: 'line',
            distance: 0,
            offset: [0, 0],
            placement: 'bottom-start',
            class: 'w-full'
          }"
          closeOnClick
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { type FieldEntry } from 'vee-validate';
import { ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

import StFormTitle from '@/components/common/st-form-title.vue';
import LaunchSettingDropdown from '@/components/launch-setting/launch-setting-dropdown.vue';
import Dropdown from '@/components/validation/dropdown.vue';
import InputText from '@/components/validation/input-text.vue';
import { REQUIRED_PROGRAM_DISPLAY_AS } from '@/constants/launch-setting.const';
import useProductStore from '@/stores/product.store';
import type {
  RequiredProgram,
  RunOptionFormFieldType
} from '@/types/launch-settings/launch-settings.type';

interface LaunchOptionPCVRProps {
  index: number;
  requiredPrograms: Array<RequiredProgram>;
  launchOptions: FieldEntry<RunOptionFormFieldType>[];
  isDisabledAll?: boolean;
}

const { t } = useI18n();
const productStore = useProductStore();

const { isProductWritable } = storeToRefs(productStore);
const MAX_LENGTH_ARGUMENT = '60';

const props = defineProps<LaunchOptionPCVRProps>();

function getRules() {
  return {
    max_length: {
      length: MAX_LENGTH_ARGUMENT,
      message: t('studio.common.def_key.exceed', { length: MAX_LENGTH_ARGUMENT })
    }
  };
}
const { index } = toRefs(props);
const isListMore = ref(false);
</script>
