import { useNuxtApp } from 'nuxt/app';
import { shallowRef } from 'vue';

import LiveConfirmDialog from '@/components/app/dialog/live-confirm-dialog.vue';
import { showAlert, showDialog } from '@/composables/useDialog';
import { TRANSLATE_LANGUAGES } from '@/constants/common.const';
import { COMMON_ERROR_MESSAGE_KEY } from '@/constants/error.const';
import {
  LAUNCH_SETTING_REQUIRED_PROGRAMS,
  REQUIRED_PROGRAM_DISPLAY_AS
} from '@/constants/launch-setting.const';
import { PLAN_STATUS } from '@/constants/products.const';
import { PRODUCT_LAUNCH_TYPE } from '@/constants/release-plan.const';
import { Confirmation, ProductVerifyStatus } from '@/enums/common.enum';
import type { LanguageModel } from '@/types/common/common.type';
import type {
  DlcLaunchSettingFormType,
  GameLaunchSettingFormType,
  LaunchSettingFormType,
  RequiredProgram,
  RunOptionFormFieldType
} from '@/types/launch-settings/launch-settings.type';

export const getRequiredProgramsConfig = (): Array<RequiredProgram> => {
  return [
    {
      type: LAUNCH_SETTING_REQUIRED_PROGRAMS.VR,
      title: 'VR',
      displayAs: REQUIRED_PROGRAM_DISPLAY_AS.DROP_BOX,
      isMultiple: false,
      isDisabled: false,
      placeHolder: 'Steam VR',
      items: []
    },
    {
      type: LAUNCH_SETTING_REQUIRED_PROGRAMS.VCREDIST,
      title: 'Visual C++ Redist',
      displayAs: REQUIRED_PROGRAM_DISPLAY_AS.DROP_BOX,
      isMultiple: true,
      isDisabled: false,
      items: []
    },
    {
      type: LAUNCH_SETTING_REQUIRED_PROGRAMS.DOTNET,
      title: '.NET',
      displayAs: REQUIRED_PROGRAM_DISPLAY_AS.DROP_BOX,
      isMultiple: false,
      isDisabled: false,
      items: []
    },
    {
      type: LAUNCH_SETTING_REQUIRED_PROGRAMS.DIRECTX,
      title: 'DirectX',
      displayAs: REQUIRED_PROGRAM_DISPLAY_AS.DROP_BOX,
      isMultiple: false,
      isDisabled: false,
      items: []
    },
    {
      type: LAUNCH_SETTING_REQUIRED_PROGRAMS.OPENAL,
      title: 'OpenAL',
      displayAs: REQUIRED_PROGRAM_DISPLAY_AS.DROP_BOX,
      isMultiple: false,
      isDisabled: false,
      items: []
    },
    {
      type: LAUNCH_SETTING_REQUIRED_PROGRAMS.GAME_ENGINE,
      title: 'Game Engine',
      displayAs: REQUIRED_PROGRAM_DISPLAY_AS.DROP_BOX,
      isMultiple: false,
      isDisabled: false,
      items: []
    }
  ];
};
export const createInstallationPathAndPcClientSettings = () => {
  return {
    installationFolder: '',
    dlcAutoInstall: false,
    gameLaunchIco: {},
    gameLaunchLogo: {},
    gameLaunchRecommend: {}
  };
};
export const createLaunchOption = (index: number, isDefault: boolean) => {
  const launchOption: RunOptionFormFieldType = {
    index,
    id: '',
    platform: '',
    runOptionNames: TRANSLATE_LANGUAGES.reduce(
      (accumulator: Record<string, string>, lang: LanguageModel) => {
        accumulator[lang.langCode] = '';
        return accumulator;
      },
      {}
    ),
    executionFile: '',
    finalExecutable: '',
    executionUrl: '',
    googleStoreUrl: '',
    appleStoreUrl: '',
    oneStoreUrl: '',
    argument: '',
    workingDirectory: '',
    processFile: '',
    serverUse: Confirmation.YES,
    isDefault,
    orderNo: index
  };
  const requiredProgramsConfig = getRequiredProgramsConfig();
  requiredProgramsConfig.forEach((item: RequiredProgram) => {
    if (item.isMultiple) {
      launchOption[item.type] = [];
    } else {
      launchOption[item.type] = '';
    }
  });
  return launchOption;
};

export const createLaunchSettingFormField = (): LaunchSettingFormType => {
  return {
    installationFolder: ''
  };
};

export const createDlcLaunchSettingFormField = (): DlcLaunchSettingFormType => {
  return {
    installationFolder: '',
    dlcAutoInstall: false
  };
};

export const createGameLaunchSettingFormField = (): GameLaunchSettingFormType => {
  return {
    installationFolder: '',
    gameLaunchIco: {},
    gameLaunchLogo: {},
    gameLaunchRecommend: {},
    launchOptions: []
  };
};

export const showErrorAlert = async () => {
  const nuxtApp = useNuxtApp();
  const { t } = nuxtApp.$i18n as { t: any };
  return await showAlert({
    content: t(COMMON_ERROR_MESSAGE_KEY),
    confirmClasses: '!max-w-full !w-full',
    cancelClasses: 'stds-button stds-button-primary'
  });
};

export const showAlertPopup = async (content: string): Promise<void> => {
  return await showAlert({
    content,
    confirmClasses: '!max-w-full !w-full',
    cancelClasses: 'stds-button stds-button-primary'
  });
};

export const showLiveConfirmDialog = async (): Promise<boolean> => {
  const result: boolean = await showDialog({
    component: shallowRef(LiveConfirmDialog),
    severity: 'info'
  });
  return result;
};

export const isProductReleased = (releaseType: string, planStatus: string) =>
  releaseType === PRODUCT_LAUNCH_TYPE.PAGE_BUILD && planStatus === PLAN_STATUS.RELEASE;

export const isProductInReleaseOrReviewStatus = (releaseType: string, verifyStatus: string, planStatus: string) => {
  if (isProductReleased(releaseType, planStatus)) {
    return true;
  }
  if (
    releaseType === PRODUCT_LAUNCH_TYPE.PAGE_BUILD &&
    (verifyStatus === ProductVerifyStatus.START ||
      verifyStatus === ProductVerifyStatus.REQUEST ||
      verifyStatus === ProductVerifyStatus.PASS)
  ) {
    return true;
  }
  return false;
};
