<template>
  <div class="flex gap-24 mt-40 w-[63.2rem]">
    <div class="flex-1">
      <st-box class="flex flex-col gap-24 !py-[3rem]">
        <div>
          <launch-installation-folder
            name="installationFolder"
            :disabled="!isProductWritable || isInReleaseOrReviewStatus"
            :desc="[
              $t('studio.prj_prod.execution_setting_folder_msg1'),
              $t('studio.prj_prod.execution_setting_folder_msg2')
            ]"
          />
        </div>
        <div v-if="typeGame === LINKAGE_PRODUCT_TYPE.DLC">
          <st-form-title
            :formTitle="$t('studio.prj_prod.execution_setting_dlc_auto_install_title')"
            required
          />
          <p role="cap1" class="flex flex-col gap-4 text-placeholder text-xs">
            {{ $t('studio.prj_prod.execution_setting_dlc_auto_install_msg1') }}
          </p>
          <radio-group
            name="dlcAutoInstall"
            :options="optionApply"
            :disabled="!isProductWritable || isInReleaseOrReviewStatus"
            class="flex gap-24 mt-12"
            :optionProps="{
              size: 'sm',
              align: 'middle'
            }"
          />
        </div>
      </st-box>
      <div v-if="!isInReleaseOrReviewStatus" class="flex justify-center mt-40">
        <launch-setting-save-button :isLived="isReleased" :onSubmit="onSubmit" :disabled="isInReleaseOrReviewStatus" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useForm } from 'vee-validate';
import { computed, defineProps } from 'vue';
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave } from 'vue-router';

import { saveInstallationPathAndPcClientSettingsApi } from '@/apis/launch-settings.api';
import StBox from '@/components/common/st-box.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import LaunchInstallationFolder from '@/components/launch-setting/launch-installation-folder.vue';
import LaunchSettingSaveButton from '@/components/launch-setting/launch-setting-save-button.vue';
import RadioGroup from '@/components/validation/radio-group.vue';
import { useApp } from '@/composables/useApp';
import { COMMON_ERROR_MESSAGE_KEY, COMMON_ERROR_REVIEW_INPROCESS_CANNOT_EDIT, STATUS_CODE } from '@/constants/error.const';
import { LINKAGE_PRODUCT_TYPE } from '@/constants/products.const';
import useProductStore from '@/stores/product.store';
import type { ErrorResponse } from '@/types/common/common.type';
import type {
  DlcLaunchSettingFormType,
  InstallPathAndPcClientSettings
} from '@/types/launch-settings/launch-settings.type';
import type { InstallPathAndPcClientSettingsRequest } from '@/types/launch-settings/launch-settings-request.type';
import type { InstallPathAndPcClientSettingsResponse } from '@/types/launch-settings/launch-settings-response.type';
import { showCommonErrorDialog } from '@/utils/common.util';
import {
  createDlcLaunchSettingFormField,
  isProductInReleaseOrReviewStatus,
  isProductReleased,
  showAlertPopup,
  showLiveConfirmDialog
} from '@/utils/launch-settings.util';

const { t } = useI18n();

const { checkProductPermission } = useApp();
interface DlcSettingFormProps {
  installationPathAndPcClientSettings: InstallPathAndPcClientSettingsResponse | undefined;
  releaseType: string;
  verifyStatus: string;
  planStatus: string;
  gameId: string;
  typeGame: string;
}
const props = defineProps<DlcSettingFormProps>();
const isReleased = computed(
  () =>
    isProductReleased(props.releaseType, props.planStatus)
);

const isInReleaseOrReviewStatus = computed(
  () =>
    isProductInReleaseOrReviewStatus(props.releaseType, props.verifyStatus, props.planStatus)
);
const productStore = useProductStore();

const { isProductWritable } = storeToRefs(productStore);
const { handleSubmit, meta, resetForm } = useForm<DlcLaunchSettingFormType>({
  initialValues: createDlcFormFields(props.installationPathAndPcClientSettings)
});

const optionApply = [
  {
    value: true,
    label: t('studio.prj_prod.execution_setting_dlc_auto_install_y')
  },
  {
    value: false,
    label: t('studio.prj_prod.execution_setting_dlc_auto_install_n')
  }
];

function createDlcFormFields(
  installationPathAndPcClientSettings: InstallPathAndPcClientSettings | undefined
): DlcLaunchSettingFormType {
  const formFields = createDlcLaunchSettingFormField();
  if (installationPathAndPcClientSettings && installationPathAndPcClientSettings.id) {
    formFields.id = installationPathAndPcClientSettings.id;
    formFields.dlcAutoInstall = installationPathAndPcClientSettings.dlcAutoInstall;
    formFields.installationFolder = installationPathAndPcClientSettings.installPath;
  }
  return formFields;
}
const handleSaveInstallationPathAndPcClientSettings = async (values: DlcLaunchSettingFormType) => {
  const payload: InstallPathAndPcClientSettingsRequest = {
    gameId: props.gameId,
    installPath: values.installationFolder,
    dlcAutoInstall: values.dlcAutoInstall,
    windowsIconUrl: '',
    productLogoImageUrl: '',
    mainRecommendImageUrl: ''
  };
  if (values.id) {
    payload.id = values.id;
  }
  const id = await saveInstallationPathAndPcClientSettingsApi(payload);
  if (!values.id) {
    values.id = id;
  }
};
const submitFormData = handleSubmit(async (values: any) => {
  try {
    let allowSave = true;
    if (isReleased.value) {
      if (meta.value.dirty) {
        allowSave = await showLiveConfirmDialog();
      } else {
        await showAlertPopup(t('studio.common.popup_case_v_release_guide'));
        allowSave = false;
      }
    }
    if (allowSave) {
      await handleSaveInstallationPathAndPcClientSettings(values);
      resetForm({ values });
      const message = isReleased.value
        ? t('studio.common.popup_case_iv_release_guide')
        : t('studio.common.popup_case_e_after_testing');
      await showAlertPopup(message);
    }
  } catch (errorInfo) {
    const error = errorInfo as ErrorResponse;
    const errorCode = error.statusCode ?? 0;

    if (errorCode === STATUS_CODE.GROUP_MIGRATION_ALREADY_PROGRESS || errorCode === STATUS_CODE.PROJECT_MIGRATION_ALREADY_PROGRESS) {
      return;
    }
    if (errorCode === STATUS_CODE.PRODUCT_RELEASE_ALREADY_EXIST || errorCode === STATUS_CODE.RATING_BUILD_OPTION_NOT_ACCEPTABLE) {
      showCommonErrorDialog(COMMON_ERROR_REVIEW_INPROCESS_CANNOT_EDIT, () => window.location.reload());
      return;
    }
    showCommonErrorDialog(COMMON_ERROR_MESSAGE_KEY);
  }
});
const onSubmit = async () => {
  if (!(await checkProductPermission())) {
    return;
  }
  submitFormData();
};
onBeforeRouteLeave(async () => {
  // if (meta.value.dirty) {
  //   const result = await showConfirm({
  //     content: t('studio.common.popup_case_a_without_saving'),
  //     confirmLabel: t('studio.common.popup_case_a_without_saving_exit_btn'),
  //     cancelVariant: 'outline'
  //   });
  //   if (!result) {
  //     return false;
  //   }
  // }
});
</script>
