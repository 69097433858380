<template>
  <s-dialog to="stds-dialog-overview" size="md" :open="true">
    <s-dialog-overlay />
    <s-dialog-panel class="!w-[93.2rem]">
      <st-dialog-header @clickClose="closeDialog()">
        <span class="font-bold">{{
          $t('studio.prj_prod.execution_setting_pc_client_preview_title')
        }}</span>
      </st-dialog-header>
      <s-dialog-content>
        <div class="pb-40">
          <p class="text-sm leading-md text-on-surface-elevation-3 mt-16">
            {{ $t('studio.prj_prod.execution_setting_pc_client_preview_msg1') }}
          </p>
          <p class="mt-16 text-lg font-medium leading-lg text-on-surface-elevation-2">
            {{ $t('studio.prj_prod.execution_setting_pc_client_preview_msg2') }}
          </p>
          <div class="mt-8 rounded-2xl h-[28rem] overflow-hidden relative">
            <div class="absolute inset-0">
              <img
                v-if="!gameLaunchRecommend"
                src="@/assets/images/run-set-up/bg-26-1-9.png"
                alt=""
                class="w-full h-full object-cover"
              />
              <img v-else :src="gameLaunchRecommend" alt="" class="w-full h-full object-cover" />
            </div>
            <div class="relative h-full flex">
              <div
                class="relative flex-1 py-48 pl-48 h-full flex flex-col before:absolute before:left-0 before:inset-y-0 before:w-[48rem] before:bg-[linear-gradient(90deg,_rgba(0,0,0,.7),_rgba(0,0,0,.00))]"
              >
                <div class="relative">
                  <div class="bg-[#232529] h-48 w-48 rounded-md">
                    <img
                      v-if="gameLaunchLogo"
                      :src="gameLaunchLogo"
                      alt=""
                      class="w-full h-full object-cover"
                    />
                  </div>
                  <p class="mt-4 text-sm font-bold text-white leading-sm">{{ productName }}</p>
                </div>
                <div class="relative mt-auto">
                  <s-button
                    variant="ghost"
                    size="sm"
                    class="!rounded-md !min-w-[8.2rem] !min-h-[3.8rem] pointer-events-none"
                  >
                    <span class="text-2xs">{{
                      $t('studio.prj_prod.execution_setting_pc_client_preview_play_btn')
                    }}</span>
                  </s-button>
                </div>
              </div>
              <div class="relative w-[14.8rem] rounded-r-2xl shrink-0 h-full overflow-hidden">
                <div class="absolute inset-y-0 right-0">
                  <img
                    src="@/assets/images/run-set-up/bg-26-1-9.png"
                    alt=""
                    class="h-full blur-[.6rem]"
                  />
                </div>
                <div class="relative h-full bg-black/25 flex flex-col items-center justify-center">
                  <span class="w-48 h-48 rounded-full bg-white flex items-center justify-center">
                    <s-icon
                      icon="ic-v2-community-random-line"
                      size="3xl"
                      class="flex text-[#1F1F1F]"
                    />
                  </span>
                  <p class="mt-4 text-xs font-bold leading-xs text-white">
                    {{
                      $t('studio.prj_prod.execution_setting_pc_client_preview_random_search_title')
                    }}
                  </p>
                  <div class="mt-8 border-t-1 border-solid border-white/10 pt-8 w-[10.4rem]">
                    <safe-html
                      class="text-center text-2xs leading-xs font-regular text-white/50"
                      tag="p"
                      :html="
                        $t('studio.prj_prod.execution_setting_pc_client_preview_random_search_msg')
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </s-dialog-content>
    </s-dialog-panel>
  </s-dialog>

  <s-portal-target name="stds-dialog-overview" />
</template>
<script setup lang="ts">
import { toRefs } from 'vue';

import safeHtml from '@/components/common/safe-html.vue';
import StDialogHeader from '@/components/common/st-dialog-header.vue';

const emit = defineEmits<{
  close: [void];
}>();

const props = defineProps<{
  productName: string;
  gameLaunchLogo: string;
  gameLaunchRecommend: string;
}>();

const { productName, gameLaunchLogo, gameLaunchRecommend } = toRefs(props);

const closeDialog = () => {
  emit('close');
};
</script>
