<template>
  <multilingual-settings-container
    v-model="currentLanguage"
    :currentTab="currentLanguage"
    :otherLanguages="otherLanguages"
    :defaultLanguage="DEFAULT_LANG_CODE"
    :onTranslateAll="
      () => {
        translateAllField();
      }
    "
    :isLoading="isTranslating"
    @save="save"
    @close="onClose"
  >
    <template #origin-content>
      <div class="mt-8 flex flex-col gap-20 mb-8">
        <template v-for="(c, idx) in content" :key="c.runOptionNames[props.sourceLang.value]">
          <div v-if="isMeetThePlatformCondition()" class="flex flex-col gap-4">
            <p class="text-md font-bold leading-lg text-on-surface-elevation-2">
              {{
                c.index === 0
                  ? $t('studio.prj_prod.this_prod.execution_setting_basic_option_title')
                  : $t('studio.ai_translation.title11_option') + ' No.' + c.index
              }}
            </p>
            <div class="flex">
              <div class="flex-1">
                <s-input
                  size="lg"
                  variant="outline"
                  :placeholder="c.runOptionNames[props.sourceLang.value]"
                  isDisabled
                />
              </div>
              <s-tooltip
                arrow
                :content="$t('studio.ai_translation.badge')"
                :duration="0"
                :distance="4"
                useFlip
                flipOnUpdate
                placement="top"
                trigger="mouseenter focus"
                :theme="'studio-tooltip'"
                :zIndex="2501"
                :allowHTML="true"
              >
                <template #target>
                  <button
                    :disabled="!isProductWritable"
                    type="button"
                    class="mx-4 flex h-44 w-44 shrink-0 items-center justify-center rounded-full hover:bg-interaction-hover text-on-surface-elevation-1 disabled:bg-disabled-variant-3 disabled:text-disabled-variant-1"
                    @click="translate(currentLanguage, idx)"
                  >
                    <s-icon size="3xl" icon="ic-v2-control-long-arrow-right-line" />
                  </button>
                </template>
              </s-tooltip>
            </div>
          </div>
        </template>
      </div>
    </template>
    <template #translated-content>
      <s-tab-panel v-for="lang in availableLanguages" :key="lang.value" :value="lang.value">
        <div class="mt-36 flex flex-col gap-48 mb-8">
          <template v-for="(c, idx) in content" :key="lang.value + c.runOptionNames[lang.value]">
            <div v-if="isMeetThePlatformCondition()" class="w-full">
              <input-text
                :key="lang.value + c.runOptionNames[lang.value]"
                v-model="langOptionNameMapping[lang.value][idx]"
                :disabled="!isProductWritable"
                label=""
                labelClass="''"
                :maxLength="maxLenOptionName"
                :allowInputMaxLength="false"
              />
            </div>
          </template>
        </div>
      </s-tab-panel>
    </template>
  </multilingual-settings-container>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

import { translateApi } from '@/apis/translation.api';
import MultilingualSettingsContainer from '@/components/common/multilingual-settings-container.vue';
import InputText from '@/components/validation/input-text.vue';
import { showConfirm } from '@/composables/useDialog';
import { DEFAULT_LANG_CODE, TRANSLATE_LANGUAGES } from '@/constants/common.const';
import { TranslationFormatType } from '@/enums/common.enum';
import { useLaunchSettingStore } from '@/stores/launch-setting.store';
import useProductStore from '@/stores/product.store';
import { type Language, type LanguageModel, type LanguageTabItem } from '@/types/common/common.type';
import type {
  MultiLanguageRunOptionsInfo,
  TranslatedLanguage
} from '@/types/launch-settings/launch-settings.type';
import type { Translated } from '@/types/translation/translation.response';
import { generateUUID } from '@/utils/uuid.util';

type LaunchOptionsMultiLanguageEmit = {
  (e: 'close', data: Array<TranslatedLanguage> | null): void;
};
const props = defineProps<{
  content: Array<MultiLanguageRunOptionsInfo>;
  sourceLang: Language;
  maxLenOptionName: string;
  mobilePlatFormName: string;
  platformNumber: number;
}>();
const NUM_OF_PLATFORMS_NEED_TO_SHOW_OPTION_NAME = 2;
const emits = defineEmits<LaunchOptionsMultiLanguageEmit>();

const launchSettingStore = useLaunchSettingStore();
const { languageOptionName } = storeToRefs(launchSettingStore);
const { isProductWritable } = storeToRefs(useProductStore());

const { content } = toRefs(props);

const fullLanguages: LanguageModel[] = TRANSLATE_LANGUAGES;

const isTranslating = ref<boolean>(false);

const langOptionNameMapping = ref<{ [key: string]: string[] }>(initLangOptionNameMapping());

const { t } = useI18n();

const availableLanguages = computed(() => {
  const defaultLangs = fullLanguages
    .filter((l: LanguageModel) => l.langCode !== props.sourceLang.value)
    .map((item: LanguageModel) => ({
      id: generateUUID(),
      name: item.langTitle,
      value: item.langCode
    }));
  return defaultLangs;
});
const currentLanguage = ref<string>(availableLanguages.value[0].value ?? '');

const otherLanguages = computed<LanguageTabItem[]>(() => {
  const langs = fullLanguages.filter((lang: LanguageModel) => lang.langCode !== props.sourceLang.value);
  return langs.map((item: LanguageModel) => ({ ...item, hasError: false }));
});

// Translate content to selected language
const translate = async (lang: string, index: number) => {
  if (!lang) {
    return;
  }

  isTranslating.value = true;

  const res = await translateApi({
    sourceLang: props.sourceLang.value,
    targetLangs: [lang],
    contents: {
      runOptionName: content.value[index].runOptionNames[props.sourceLang.value]
    },
    formats: {
      runOptionName: TranslationFormatType.TEXT
    }
  });

  if (!res) {
    isTranslating.value = false;
    return;
  }

  langOptionNameMapping.value[lang][index] = res.translated[0]?.translatedContents?.runOptionName;

  isTranslating.value = false;
};

function initLangOptionNameMapping(): Record<string, string[]> {
  const data: Record<string, string[]> = {};
  fullLanguages.forEach((item: LanguageModel) => {
    data[item.langCode] = [];
  });
  return data;
}

function isMeetThePlatformCondition(): boolean {
  return props.platformNumber >= NUM_OF_PLATFORMS_NEED_TO_SHOW_OPTION_NAME;
}

function hasTranslatedOptionName(): boolean {
  const langKeys = Object.keys(langOptionNameMapping.value);
  for (const lang of langKeys) {
    if (lang !== props.sourceLang.value) {
      const runOptionNames = langOptionNameMapping.value[lang];
      for (const value of runOptionNames) {
        if (value.trim() !== '') {
          return true;
        }
      }
    }
  }
  return false;
}

const translateAllField = async () => {
  const hasTranslated = await hasTranslatedOptionName();
  if (hasTranslated) {
    const confirm = await showConfirm({
      content: t('studio.common.popup_case_ai_overwrite'),
      confirmLabel: t('studio.common.popup_case_translation_in_progress_btn'),
      cancelLabel: t('studio.common.popup_case_cancel_btn'),
      cancelVariant: 'outline'
    });
    if (!confirm) {
      return;
    }
  }
  isTranslating.value = true;

  if (!content.value) {
    return;
  }

  const keys = Object.keys(content.value).filter(
    (key: string) => key !== 'langCode' && key !== 'default'
  );

  const contents = keys.reduce((acc: Record<string, string | boolean | string[]>, key: string) => {
    acc[key] = content.value[Number(key)].runOptionNames[props.sourceLang.value];
    return acc;
  }, {});

  const formats = Object.keys(contents).reduce(
    (acc: Record<string, TranslationFormatType.HTML | TranslationFormatType.TEXT>, key: string) => {
      acc[key] = TranslationFormatType.TEXT;
      return acc;
    },
    {}
  );

  const result = await translateApi({
    sourceLang: props.sourceLang.value,
    targetLangs: availableLanguages.value.map((lang: any) => lang.value),
    contents,
    formats
  });

  if (!result) {
    isTranslating.value = false;
    return;
  }

  result.translated.forEach((item: Translated) => {
    const lang = item.lang;
    const translatedContent = item.translatedContents;

    if (langOptionNameMapping.value[lang]) {
      Object(keys).forEach((key: string) => {
        langOptionNameMapping.value[lang][Number(key)] = translatedContent[key];
      });
    }
  });

  isTranslating.value = false;
};

const save = () => {
  languageOptionName.value.forEach((lang: TranslatedLanguage) => {
    lang.runOptionNames = langOptionNameMapping.value[lang.langCode];
  });
  emits('close', languageOptionName.value);
};

const init = async () => {
  const languageData = fullLanguages.reduce(
    (acc: { [key: string]: string[] }, lang: LanguageModel) => {
      acc[lang.langCode] = content.value.map((item: any) => {
        return item.runOptionNames[lang.langCode];
      });
      return acc;
    },
    {}
  );

  languageData[props.sourceLang.value] = content.value.map((c: MultiLanguageRunOptionsInfo) => {
    return c.runOptionNames[props.sourceLang.value];
  });
  langOptionNameMapping.value = languageData;
};

const onClose = () => {
  if (isTranslating.value) {
    return;
  }

  emits('close', null);
};

init();
</script>
