<template>
  <st-form-title
    :formTitle="$t('studio.prj_prod.this_prod.execution_setting_folder_title')"
    required
  />
  <p class="flex flex-col gap-4 text-placeholder mt-4 font-regular text-xs">
    <span v-if="Array.isArray(desc) === false">
      {{ desc || $t('studio.prj_prod.execution_setting_folder_msg1') }}
    </span>
    <span v-for="(text, index) of desc" v-else :key="index">{{ text }}</span>
  </p>
  <div class="mt-8">
    <input-text
      :placeholder="$t('studio.prj_prod.this_prod.execution_setting_folder_name_placeholder')"
      :name="name"
      :disabled="disabled"
      :countable="false"
      :rules="{
        required: $t('studio.prj_prod.this_prod.execution_setting_folder_name_req_msg'),
        regex: {
          regex: NO_SPECIAL_CHARS_KR_REGEX,
          message: $t('studio.prj_prod.this_prod.execution_setting_folder_name_invalid_msg')
        },
        max_length: {
          length: MAX_LENGTH_INSTALLATION_FOLDER_NAME,
          message: $t('studio.common.def_key.exceed', {
            length: MAX_LENGTH_INSTALLATION_FOLDER_NAME
          })
        }
      }"
      size="lg"
      @blur="removeTheBackSlashFromStartAndEndPosition"
    />
  </div>
</template>
<script lang="ts" setup>
import { useFieldValue, useSetFieldValue } from 'vee-validate';
import { defineProps } from 'vue';

import StFormTitle from '@/components/common/st-form-title.vue';
import InputText from '@/components/validation/input-text.vue';
import { MAX_LENGTH_INSTALLATION_FOLDER_NAME } from '@/constants/launch-setting.const';
import { NO_SPECIAL_CHARS_KR_REGEX } from '@/constants/regex.const';
import type { ValidationRule } from '@/types/validation.type';

interface InstallationFolderProps {
  disabled?: boolean;
  rules?: ValidationRule;
  desc?: string | string[];
  name: string;
}
const props = withDefaults(defineProps<InstallationFolderProps>(), {
  disabled: false,
  rules: undefined,
  desc: ''
});

const installationPath = useFieldValue(props.name);
const setInstallationPathValue = useSetFieldValue(props.name);
const removeTheBackSlashFromStartAndEndPosition = () => {
  const value = installationPath.value as string;
  setInstallationPathValue(value.replace(/^\s*\\*\s*|\s*\\*\s*$/g, ''));
};
</script>
