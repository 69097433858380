<template>
  <div class="flex-1">
    <st-box>
      <div class="flex gap-4 items-center">
        <span class="w-12 h-12 rounded-full bg-[#FFC226] shrink-0"></span>
        <span class="text-md leading-lg font-medium text-on-surface-elevation-2">{{
          $t('studio.prj_prod.status_in_review')
        }}</span>
      </div>
      <p class="text-xs leading-xs text-placeholder font-regular mt-4">
        {{ $t('studio.prj_prod.execution_setting.post_release_update_guide') }}
      </p>
      <template v-for="(displayRunOption, index) in displayRunOptions" :key="index">
        <template v-if="displayRunOption.type === PLATFORM.PC">
          <div class="mt-24 pt-24 border-t-1 border-solid border-border flex flex-col gap-20">
            <st-folding-evaluate-item
              :titleValue="
                $t('studio.prj_prod.execution_setting.post_release_update_info_run_option')
              "
              :dataValue="`${$t(
                'studio.prj_prod.execution_setting.post_release_update_info_run_option'
              )} ${index + 1}`"
            />
            <st-folding-evaluate-item
              :titleValue="
                $t('studio.prj_prod.execution_setting.post_release_update_info_platform')
              "
              :dataValue="displayRunOption.type"
            />
            <st-folding-evaluate-item
              :titleValue="
                $t('studio.prj_prod.execution_setting.post_release_update_info_opt_name')
              "
              :dataValue="displayRunOption.runOptionNames[locale]"
            />
            <st-folding-evaluate-item
              :titleValue="
                $t('studio.prj_prod.execution_setting.post_release_update_info_exe_file')
              "
              :dataValue="displayRunOption.executable"
            />

            <!-- START 간단히 보기/더 보기 (Show / Hide) content -->
            <div v-if="isOpenMore[index]">
              <div class="flex flex-col gap-20">
                <st-folding-evaluate-item
                  v-if="displayRunOption.programParams"
                  :titleValue="
                    $t('studio.prj_prod.execution_setting.post_release_update_info_argument_value')
                  "
                  :dataValue="displayRunOption.programParams"
                />
                <st-folding-evaluate-item
                  v-if="displayRunOption.workingPath"
                  :titleValue="
                    $t('studio.prj_prod.execution_setting.post_release_update_info_path')
                  "
                  :dataValue="displayRunOption.workingPath"
                />
                <st-folding-evaluate-item
                  v-if="displayRunOption.processFile"
                  :titleValue="
                    $t('studio.prj_prod.execution_setting.post_release_update_info_process_file')
                  "
                  :dataValue="displayRunOption.processFile"
                />
                <st-folding-evaluate-item
                  v-for="(requiredProgram, rIndex) in displayRunOption.displayRequiredPrograms"
                  :key="rIndex"
                  :titleValue="requiredProgram.title"
                  :dataValue="requiredProgram.items.join(' / ')"
                />
              </div>
            </div>
          </div>

          <div v-if="displayRunOption.showViewMoreButton" class="mt-24 flex justify-center">
            <button
              type="button"
              class="h-[3.2rem] inline-flex items-center gap-4 px-16"
              @click="onOpenMore(index)"
            >
              <span class="text-md leading-sm font-regular text-on-surface-elevation-3">
                {{
                  isOpenMore[index]
                    ? $t('studio.prj_prod.this_prod.execution_setting_option_collapse_all_msg')
                    : $t('studio.prj_prod.this_prod.execution_setting_option_expand_all_msg')
                }}
              </span>
              <s-icon
                size="xl"
                icon="ic-v2-control-arrow-down-line"
                class="text-on-surface-elevation-3 flex"
                :class="{ 'rotate-180': isOpenMore[index] }"
              />
            </button>
          </div>
        </template>
        <div
          v-if="displayRunOption.type === PLATFORM.WEB"
          class="mt-24 pt-24 border-t-1 border-solid border-border flex flex-col gap-20"
        >
          <st-folding-evaluate-item
            :titleValue="
              $t('studio.prj_prod.execution_setting.post_release_update_info_run_option')
            "
            :dataValue="`${$t(
              'studio.prj_prod.execution_setting.post_release_update_info_run_option'
            )} ${index + 1}`"
          />
          <st-folding-evaluate-item
            :titleValue="$t('studio.prj_prod.execution_setting.post_release_update_info_platform')"
            :dataValue="capitalizeFirstLetter(displayRunOption.type)"
          />
          <st-folding-evaluate-item
            :titleValue="$t('studio.prj_prod.execution_setting.post_release_update_info_opt_name')"
            :dataValue="displayRunOption.runOptionNames[locale]"
          />
          <st-folding-evaluate-item
            :titleValue="$t('studio.prj_prod.execution_setting.post_release_update_info_exe_url')"
            :dataValue="displayRunOption.resources.webExecuteUrl"
          />
        </div>
      </template>
      <div
        v-if="settings?.installPath"
        class="mt-24 pt-24 border-t-1 border-solid border-border flex flex-col gap-20"
      >
        <st-folding-evaluate-item
          :titleValue="$t('studio.prj_prod.execution_setting.post_release_update_info_folder')"
          :dataValue="settings?.installPath || ''"
        />
      </div>
      <div
        v-if="settings?.windowsIconUrl"
        class="mt-24 pt-24 border-t-1 border-solid border-border flex flex-col gap-20"
      >
        <div class="flex flex-1 items-center gap-16">
          <span
            class="relative h-48 w-48 shrink-0 overflow-hidden rounded-lg border-1 border-solid border-border"
          >
            <img :src="settings?.windowsIconUrl" alt="" class="h-full w-full object-cover" />
          </span>
          <st-folding-evaluate-item
            :titleValue="$t('studio.prj_prod.execution_setting_pc_client_launcher_image_title')"
            :dataValue="handleImageName(settings?.windowsIconUrl)"
            dataClass="text-md leading-lg"
          />
        </div>
        <div v-if="settings?.productLogoImageUrl" class="flex flex-1 items-center gap-16">
          <span
            class="relative h-48 w-48 shrink-0 overflow-hidden rounded-lg border-1 border-solid border-border"
          >
            <img :src="settings?.productLogoImageUrl" alt="" class="h-full w-full object-cover" />
          </span>
          <st-folding-evaluate-item
            :titleValue="$t('studio.prj_prod.execution_setting_pc_client_logo_image_title')"
            :dataValue="handleImageName(settings?.productLogoImageUrl)"
            dataClass="text-md leading-lg"
          />
        </div>
        <div v-if="settings?.mainRecommendImageUrl" class="flex flex-1 items-center gap-16">
          <span
            class="relative h-48 w-48 shrink-0 overflow-hidden rounded-lg border-1 border-solid border-border"
          >
            <img :src="settings?.mainRecommendImageUrl" alt="" class="h-full w-full object-cover" />
          </span>
          <st-folding-evaluate-item
            :titleValue="
              $t('studio.prj_prod.execution_setting_pc_client_client_wallpaper_image_title')
            "
            :dataValue="handleImageName(settings?.mainRecommendImageUrl)"
            dataClass="text-md leading-lg"
          />
        </div>
      </div>
    </st-box>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, defineProps, ref } from 'vue';

import StBox from '@/components/common/st-box.vue';
import StFoldingEvaluateItem from '@/components/common/st-folding-evaluate-item.vue';
import { DEFAULT_LANG_CODE, PLATFORM } from '@/constants/common.const';
import { useUserStore } from '@/stores/user.store';
import type {
  DisplayRequiredProgram,
  DisplayRunOptions,
  InstallPathAndPcClientSettings,
  RequiredProgram,
  RequireProgramGroup,
  RunOptionGroup
} from '@/types/launch-settings/launch-settings.type';
import { getRequiredProgramsConfig } from '@/utils/launch-settings.util';
import { capitalizeFirstLetter } from '@/utils/string.util';

const userStore = useUserStore();
const { selectedGroupInfo } = storeToRefs(userStore);

const isOpenMore = ref<Array<boolean>>([]);

interface LaunchSettingReviewProps {
  installationPathAndPcClientSettings: InstallPathAndPcClientSettings | undefined;
  runOptions: Array<RunOptionGroup> | undefined;
}
const props = defineProps<LaunchSettingReviewProps>();
const requiredProgramsConfig: Array<RequiredProgram> = getRequiredProgramsConfig();
const displayRunOptions = filterRequiredPrograms(props.runOptions || []);
const settings = props.installationPathAndPcClientSettings;
const locale = computed(() => {
  return selectedGroupInfo.value?.languageCd ?? DEFAULT_LANG_CODE;
});

function isShowViewMoreButton(displayRunOption: DisplayRunOptions): boolean {
  if (displayRunOption.type !== PLATFORM.PC) {
    return false;
  }
  if (
    displayRunOption.displayRequiredPrograms.length > 0 ||
    displayRunOption.processFile ||
    displayRunOption.workingPath ||
    displayRunOption.programParams
  ) {
    return true;
  }
  return false;
}
function onOpenMore(index: number) {
  isOpenMore.value[index] = !isOpenMore.value[index];
}
function filterRequiredPrograms(runOptions: Array<RunOptionGroup>): Array<DisplayRunOptions> {
  const displayRunOptions: Array<DisplayRunOptions> = [];
  runOptions.forEach((runOption: RunOptionGroup) => {
    const displayRunOption: DisplayRunOptions = {
      ...runOption,
      displayRequiredPrograms: [],
      showViewMoreButton: false
    };
    isOpenMore.value.push(false);
    if (displayRunOption.type === PLATFORM.PC) {
      const displayRequiredPrograms: Array<DisplayRequiredProgram> = [];
      const requiredProgramsResponse = displayRunOption.requiredPrograms;
      requiredProgramsConfig.forEach((requiredProgramConfig: RequiredProgram) => {
        const { type, title } = requiredProgramConfig;
        const program = requiredProgramsResponse.find(
          (requiredProgram: RequireProgramGroup) => requiredProgram.programType === type
        );
        if (program) {
          displayRequiredPrograms.push({
            title,
            items: program.programNames
          });
        }
      });
      displayRunOption.displayRequiredPrograms = displayRequiredPrograms;
    }
    displayRunOption.showViewMoreButton = isShowViewMoreButton(displayRunOption);
    displayRunOptions.push(displayRunOption);
  });
  return displayRunOptions;
}

const handleImageName = (url: string | undefined): string => {
  if (!url) {
    return 'image';
  }
  const urlParams = new URLSearchParams(url.split('/').pop()?.split('?')[1] ?? '');

  return urlParams.get('name') ?? 'image';
};
</script>
