<template>
  <div
    class="stds-dropdown-line-button px-12 focus-within:px-[1.1rem] h-32 text-btn3 overflow-hidden leading-btn3 w-full"
    :class="{ '!bg-disabled-variant-3': disabled }"
    @click.stop="setDowndownListVisibility"
  >
    <input-text
      v-model="dropdownValueNameRef"
      :disabled="disabled"
      :readonly="true"
      :clearable="false"
      :placeholder="placeholder"
      containerClass="!bg-transparent !pl-0 !pr-0 border-none"
      inputClass="overflow-hidden text-ellipsis whitespace-nowrap cursor-pointer"
      size="sm"
      variant="fill"
    />
    <s-icon
      size="xs"
      icon="ic-v2-control-select-arrow-down-fill"
      class="ml-8"
      :class="{ 'rotate-180': isListMore, 'text-disabled-variant-1': disabled }"
    />
  </div>
  <div
    v-show="isListMore"
    v-click-outside="clickOutSideDropdownList"
    class="stds-dropdown-list absolute inset-x-0 top-[calc(100%+.4rem)] !py-0 !max-h-[32rem] zIndex10"
  >
    <checkbox-group
      :name="name"
      :options="dropdownOptions.value || []"
      size="sm"
      :disabled="disabled"
      :optionProps="{ align: 'middle', size: 'sm' }"
      class="pl-16 flex flex-col gap-8 py-16"
      @update:modelValue="
        (e:string[]) =>
          createDropboxDisplayName(e)
      "
    >
      <template #extension-option>
        <div class="flex items-center">
          <div class="stds-checkbox-container items-center min-h-16 min-w-16">
            <input
              :id="name"
              class="stds-checkbox peer h-16 w-16 after:text-xs focus-visible:border-1"
              type="checkbox"
              :checked="isCheckAll"
              :disabled="isCheckAll"
              @change="handleUncheckAll"
            />
            <label class="stds-checkbox-label text-text3" :for="name">
              <span class="text-on-surface-elevation-2">{{ placeholder }}</span>
            </label>
          </div>
        </div>
      </template>
    </checkbox-group>
  </div>
</template>
<script setup lang="ts">
import { useFieldValue, useSetFieldValue } from 'vee-validate';
import type { Ref } from 'vue';
import { computed, defineProps, ref, toRef, watch } from 'vue';

import CheckboxGroup from '@/components/validation/checkbox-group.vue';
import InputText from '@/components/validation/input-text.vue';
import type { FormOptionGroup } from '@/types/common/form.type';

interface LaunchSettingDropDownProps {
  items: Array<FormOptionGroup>;
  name: string;
  maxOptionCanSelect: number;
  disabled?: boolean;
  placeholder?: string;
}
const props = withDefaults(defineProps<LaunchSettingDropDownProps>(), {
  disabled: false,
  placeholder: ''
});

const dropdownValues: Ref<Array<string>> = useFieldValue(props.name);
const setDropDownValues = useSetFieldValue(props.name);
const dropdownValueNameRef = ref<string>(createDropdownName(dropdownValues.value));
const isListMore = ref(false);
const isCheckAll = ref(false);
const itemsRef = toRef(props.items);
const dropdownOptions = computed(() => {
  if (props.maxOptionCanSelect && Array.isArray(dropdownValues.value)) {
    if (dropdownValues.value.length === props.maxOptionCanSelect) {
      disableDropDownOptions(dropdownValues.value);
    } else {
      enableDropDownOptions();
    }
  }
  return itemsRef;
});

const createDropboxDisplayName = (selectedvalues: string[]) => {
  const labels = selectedvalues.map((value: string) => {
    const index = props.items.findIndex((item: FormOptionGroup<string>) => item.value === value);
    if (index !== -1) {
      return props.items[index].label;
    }
    return '';
  });
  dropdownValueNameRef.value = labels.join(' / ');
};
function handleUncheckAll(e: any) {
  if (e.target.checked === false) {
    if (Array.isArray(dropdownValues.value) && dropdownValues.value.length === 0) {
      dropdownValueNameRef.value = '';
    }
  } else {
    setDropDownValues([]);
  }
}
function createDropdownName(selectedValues: Array<string>): string {
  if (selectedValues && selectedValues.length) {
    const len = selectedValues.length;
    const itemLen = props.items.length;
    const names: Array<string> = [];
    for (let i = 0; i < len; i++) {
      const selectedvalue = selectedValues[i];
      for (let j = 0; j < itemLen; j++) {
        const item = props.items[j];
        if (selectedvalue === item.value) {
          names.push(item.label);
          break;
        }
      }
    }
    return names.join(' / ');
  }
  return '';
}
function disableDropDownOptions(dropdownValues: Array<string>) {
  const len = itemsRef.value.length;
  for (let i = 0; i < len; i++) {
    const itemValue = itemsRef.value[i].value;
    if (!dropdownValues.includes(itemValue)) {
      itemsRef.value[i].isDisabled = true;
    }
  }
}
function enableDropDownOptions() {
  const len = itemsRef.value.length;
  for (let i = 0; i < len; i++) {
    itemsRef.value[i].isDisabled = false;
  }
}
function clickOutSideDropdownList() {
  if (isListMore.value) {
    isListMore.value = false;
  }
}
function setDowndownListVisibility() {
  if (!props.disabled) {
    isListMore.value = !isListMore.value;
  }
}
watch(
  dropdownValues,
  (newDropdownValues: string[]) => {
    if (!newDropdownValues || newDropdownValues.length === 0) {
      isCheckAll.value = true;
      dropdownValueNameRef.value = props.placeholder;
    } else {
      isCheckAll.value = false;
    }
  },
  { immediate: true }
);
</script>
<style lang="css" scoped>
.zIndex10 {
  z-index: 10;
}
</style>
