<template>
  <div>
    <st-form-title :formTitle="label" :required="required" />
    <input-product-file
      :inList="false"
      :resolution="formatDimension"
      :extensions="extensions"
      :fileLabel="fileLabel || ''"
      :type="FILE_TYPE.IMAGE"
      :name="name"
      :isDisabled="disabled || false"
      :showMessageWhenDisabled="showMessageWhenDisabled || false"
      :disabledMessage="$t('studio.common.popup_case_h_not_edit')"
      :cropBox="cropBox"
      :showEditButton="canEdit"
      :stencilSize="stencilBox"
      expandFileNameByRightWing
      :isGuideShown="isGuideShown"
      :isHiddenAddLogo="isHiddenAddLogo"
      :disableOpacity="disableOpacity"
      :guideType="PRODUCT_PAGE_IMAGE_EDITOR_GUIDE_TYPE.CUSTOM"
      :safeArea="{
        width: 240,
        height: 208,
        left: '50%'
      }"
      :dialogName="label"
    />
    <st-error-message
      v-if="errorMessage"
      :errorMessage="generateErrorMsg(errorMessage, RuleNames.REQUIRED)"
    />
  </div>
</template>
<script lang="ts" setup>
import { useField } from 'vee-validate';
import { type Ref } from 'vue';
import { useI18n } from 'vue-i18n';

import StErrorMessage from '@/components/common/st-error-message.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import InputProductFile from '@/components/product-page/input-product-file.vue';
import { FILE_TYPE, PRODUCT_PAGE_IMAGE_EDITOR_GUIDE_TYPE } from '@/enums/product-page.enum';
import { RuleNames } from '@/enums/validation.enum';
import type { PcClientImageSettings } from '@/types/launch-settings/launch-settings.type';
import { generateErrorMsg } from '@/utils/validation.util';

const props = defineProps<PcClientImageSettings>();
const { t } = useI18n();
const errorMessageText = t('studio.prj_prod.execution_setting_pc_client_launcher_image_req_msg');
const icoErrorMessageText = t('studio.file_upload.case_unsupported_format_msg');

const {
  errorMessage
}: { value: Ref<Record<string, string>>; errorMessage: Ref<string | undefined> } = useField(
  () => props.name,
  (value: Record<string, string>) => {
    // Check required first
    if (props.required && !value.linkCdn) {
      return errorMessageText;
    }

    // Check ico validation
    if (props.extensions?.includes('ico') && value.fileType === 'invalid-ico') {
      return icoErrorMessageText;
    }

    return true;
  }
);

const formatDimension = props.dimensions.join('x');
</script>
