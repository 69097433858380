<template>
  <right-wing-item
    :rightWingTitle="`${$t('studio.prj_prod.execution_install_new_guide_web_title')}`"
    class="mb-24"
  >
    <s-text as="span" :role="stextRole" :class="stextClass">
      {{ $t('studio.prj_prod.execution_exe_opt_enabled_guide5_1') }}
      <ul :class="itemContainerCssClass">
        <safe-html
          :html="$t('studio.prj_prod.execution_install_new_guide_web_sub_des1')"
          :class="itemCssClass"
          tag="li"
        />
        <safe-html
          :html="$t('studio.prj_prod.execution_install_new_guide_web_sub_des2')"
          :class="itemCssClass"
          tag="li"
        />
      </ul>
    </s-text>
    <div class="mt-8">
      <a :href="guideImagesSrc" target="_blank">
        <img :src="guideImagesSrc" alt="" class="w-full block" />
      </a>
    </div>
  </right-wing-item>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import * as GuideImages from '@/assets/images/run-set-up/guide-flatform-web';
import RightWingItem from '@/components/app/right-wing/item.vue';
import SafeHtml from '@/components/common/safe-html.vue';

const { locale } = useI18n();
const itemCssClass =
  'relative pl-16 before:absolute before:top-[.9rem] before:left-4 before:w-4 before:h-4 before:rounded-full before:bg-[currentColor]';
const itemContainerCssClass = 'text-on-surface-elevation-3 font-regular';
const stextRole = 'text4';
const stextClass = 'block font-medium mt-8 text-on-surface-elevation-2';
const guideImages = ref<Record<string, string>>(GuideImages);
const guideImagesSrc = computed(() => guideImages.value[`Guide_${locale.value.replace('-', '_')}`]);
</script>
