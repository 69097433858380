<template>
  <div class="flex-1 w-[63.2rem]">
    <st-box>
      <form-header-folding
        ref="formHeaderFoldingComponent"
        :adminName="$t('studio.prj_prod.this_prod.execution_setting_option_title')"
      >
        <template #button>
          <div class="shrink-0 flex gap-8 items-center">
            <span
              class="text-2xs leading-xs rounded-full px-[.6rem] h-[2.2rem] flex items-center text-gray500 bg-gray60"
            >
              {{ $t(`${baseLanguageName}`) }}
            </span>
            <s-button
              variant="outline"
              icon="ic-v2-communication-translate-line"
              size="sm"
              :isDisabled="!isMeetThePlatformCondition || isInReleaseOrReviewStatus"
              @click="onSettingMultiLanguage"
            >
              {{ $t('studio.ai_translation.title1_settings') }}
            </s-button>
          </div>
          <div class="shrink-0">
            <s-button
              variant="secondary"
              icon="ic-v2-control-add-line"
              size="sm"
              class="!pl-[.6rem] !pr-8"
              :isDisabled="!defaultRunOptionPlatform || isInReleaseOrReviewStatus"
              @click="onPushLaunch"
            >
              {{ $t('studio.prj_prod.this_prod.execution_setting_option_add_btn') }}
            </s-button>
          </div>
        </template>
        <template #content>
          <v-field-array name="launchOptions">
            <div
              v-for="(field, idx) in launchOptions"
              :key="idx"
              class="pt-24 border-t-1 border-solid border-border"
              :class="{ 'mt-24': !launchOptions[idx].value.isDefault }"
            >
              <div :class="{ 'flex items-center': !launchOptions[idx].value.isDefault }">
                <st-form-title
                  :formTitle="`${
                    launchOptions[idx].value.isDefault
                      ? $t('studio.prj_prod.this_prod.execution_setting_basic_option_title')
                      : $t('studio.prj_prod.this_prod.execution_setting_option_title')
                  } ${launchOptions[idx].value.isDefault ? '' : idx}`"
                  :required="launchOptions[idx].value.isDefault"
                  :class="{ 'flex-1': !launchOptions[idx].value.isDefault }"
                />
                <template v-if="launchOptions[idx].value.isDefault">
                  <p class="text-xs leading-xs text-placeholder font-regular mt-4">
                    {{ $t('studio.prj_prod.this_prod.execution_setting_option_msg') }}
                  </p>
                  <p class="text-xs leading-xs text-placeholder font-regular">
                    {{ $t('studio.prj_prod.this_prod.execution_setting_option_msg1') }}
                  </p>
                </template>
                <s-button
                  v-else
                  variant="outline"
                  size="sm"
                  :isDisabled="!isProductWritable || isInReleaseOrReviewStatus"
                  @click="handleRemoveItem(idx)"
                >
                  {{ $t('studio.prj_prod.execution_setting_option_delete_btn') }}
                </s-button>
              </div>

              <!-- safe area -->
              <div class="mt-20 flex flex-col gap-12">
                <div class="flex items-start gap-8">
                  <div class="w-[18rem] shrink-0 mt-[.5rem]">
                    <st-form-title
                      :formTitle="
                        $t('studio.prj_prod.this_prod.execution_setting_option_platform_msg')
                      "
                      subTitle
                      :required="launchOptions[idx].value.isDefault"
                    />
                  </div>
                  <div class="flex-1 relative">
                    <dropdown
                      :key="platFormDropDownKeys[idx][PLATFORM_PREFIX]"
                      :name="`launchOptions[${idx}].platform`"
                      :disabled="!isProductWritable || isInReleaseOrReviewStatus"
                      :options="PLATFORMS_DISPLAY"
                      :placeholder="
                        t(
                          'studio.prj_prod.this_prod.execution_setting_option_platform_dropbox_place_holder'
                        )
                      "
                      :dropdownProps="{
                        size: 'sm',
                        variant: 'line',
                        distance: 0,
                        offset: [0, 0],
                        placement: 'bottom-start',
                        class: 'w-full'
                      }"
                      :rules="
                        launchOptions[idx].value.isDefault
                          ? {
                            required: t(
                              'studio.prj_prod.this_prod.execution_setting_option_platform_dropbox_req_msg'
                            )
                          }
                          : {}
                      "
                      closeOnClick
                      @update:modelValue="(platForm:string|number) => handleUpdatePlatform(platForm as string, idx)"
                    >
                      <template
                        v-if="defaultRunOptionPlatform !== PLATFORM.WEB && idx !== 0"
                        #[`option-${1}`]="{ option }"
                      >
                        <li>
                          <button
                            class="stds-dropdown-list-item text-disabled-variant-1 bg-[rgba(204 204 204 / 1)] cursor-not-allowed"
                            :disabled="true"
                          >
                            {{ option.label }}
                          </button>
                        </li>
                      </template>
                      <template
                        v-if="defaultRunOptionPlatform !== PLATFORM.PC && idx !== 0"
                        #[`option-${0}`]="{ option }"
                      >
                        <li>
                          <button
                            class="stds-dropdown-list-item text-disabled-variant-1 bg-[rgba(204 204 204 / 1)] cursor-not-allowed"
                            :disabled="true"
                          >
                            {{ option.label }}
                          </button>
                        </li>
                      </template>
                    </dropdown>
                  </div>
                </div>
                <div
                  v-if="optionPlatformList[idx] && isMeetThePlatformCondition"
                  class="flex items-start gap-8"
                >
                  <div class="w-[18rem] shrink-0 mt-[.5rem]">
                    <st-form-title
                      :formTitle="$t('studio.prj_prod.execution_exe_opt_enabled_guide_opt_name')"
                      subTitle
                      required
                    />
                  </div>
                  <div class="flex-1 relative">
                    <input-text
                      :countable="false"
                      :disabled="!isProductWritable || isInReleaseOrReviewStatus"
                      :rules="{
                        required: {
                          message: t(
                            'studio.prj_prod.this_prod.execution_setting_option_name_req_msg'
                          )
                        },
                        max_length: {
                          length: MAX_LENGTH_OPTION_NAME,
                          message: $t('studio.common.def_key.exceed', {
                            length: MAX_LENGTH_OPTION_NAME
                          })
                        }
                      }"
                      size="sm"
                      :name="`launchOptions[${idx}].runOptionNames.${locale}`"
                      :placeholder="
                        $t('studio.prj_prod.this_prod.execution_setting_option_name_place_holder')
                      "
                      @update:modelValue="() => delayAndCheckDuplicatedOptionName(idx)"
                    />
                  </div>
                </div>

                <template v-if="optionPlatformList[idx] === PLATFORM.PC">
                  <div class="flex items-start gap-8">
                    <div class="w-[18rem] shrink-0 mt-[.5rem]">
                      <st-form-title
                        :formTitle="
                          $t(
                            'studio.prj_prod.this_prod.execution_setting_option_executing_file_msg'
                          )
                        "
                        subTitle
                        required
                      />
                    </div>
                    <div class="flex-1 relative">
                      <input-text
                        :countable="false"
                        :disabled="!isProductWritable || isInReleaseOrReviewStatus"
                        :rules="{
                          required: {
                            message: t(
                              'studio.prj_prod.this_prod.execution_setting_option_executing_file_req_msg'
                            )
                          },
                          max_length: {
                            length: MAX_LENGTH_INSTALLATION_FOLDER_NAME,
                            message: $t('studio.common.def_key.exceed', {
                              length: MAX_LENGTH_INSTALLATION_FOLDER_NAME
                            })
                          }
                        }"
                        size="sm"
                        :name="`launchOptions[${idx}].executionFile`"
                        :placeholder="
                          t(
                            'studio.prj_prod.this_prod.execution_setting_option_executing_file_place_holder'
                          )
                        "
                        @blur="() => removeTheBeginningSlash(idx, 'executionFile')"
                      />
                    </div>
                  </div>
                  <div
                    v-show="isOpenDetail[idx] && optionPlatformList[idx] === PLATFORM.PC"
                    class="flex items-start gap-8"
                  >
                    <div class="w-[18rem] shrink-0 mt-[.5rem]">
                      <st-form-title
                        :formTitle="
                          $t('studio.prj_prod.this_prod.execution_setting_option_final_run_file')
                        "
                        subTitle
                      />
                    </div>
                    <div class="flex-1 relative">
                      <input-text
                        :countable="false"
                        :disabled="!isProductWritable || isInReleaseOrReviewStatus"
                        :rules="{
                          max_length: {
                            length: MAX_LENGTH_INSTALLATION_FOLDER_NAME,
                            message: $t('studio.common.def_key.exceed', {
                              length: MAX_LENGTH_INSTALLATION_FOLDER_NAME
                            })
                          }
                        }"
                        size="sm"
                        :name="`launchOptions[${idx}].finalExecutable`"
                        :placeholder="
                          t(
                            'studio.prj_prod.this_prod.execution_setting_option_final_run_file_place_holder'
                          )
                        "
                        @blur="() => removeTheBeginningSlash(idx, 'finalExecutable')"
                      />
                    </div>
                  </div>
                </template>

                <div v-if="optionPlatformList[idx] === PLATFORM.WEB" class="flex items-start gap-8">
                  <div class="w-[18rem] shrink-0 mt-[.5rem]">
                    <st-form-title
                      :formTitle="$t('studio.prj_prod.execution_setting_exe_url')"
                      subTitle
                      required
                    />
                  </div>
                  <div class="flex-1 relative">
                    <input-text
                      :countable="false"
                      :disabled="!isProductWritable || isInReleaseOrReviewStatus"
                      :rules="{
                        required: {
                          message: $t('studio.prj_prod.execution_setting_exe_url_req_msg')
                        },
                        max_length: {
                          length: MAX_LENGTH_EXECUTION_URL,
                          message: $t('studio.common.def_key.exceed', {
                            length: MAX_LENGTH_EXECUTION_URL
                          })
                        },
                        regex: {
                          regex: URL_REGEX,
                          message: $t('studio.prj_prod.execution_setting_exe_url_req_msg')
                        }
                      }"
                      size="sm"
                      :name="`launchOptions[${idx}].executionUrl`"
                      :placeholder="$t('studio.prj_prod.execution_setting_exe_url_place_holder')"
                    />
                  </div>
                </div>

                <!-- detail -->
                <div v-show="isOpenDetail[idx] && optionPlatformList[idx] === PLATFORM.PC">
                  <launch-option-pc-vr-form
                    v-if="optionPlatformList[idx] === PLATFORM.PC"
                    :launchOptions="launchOptions"
                    :requiredPrograms="requiredProgramsRef"
                    :isDisabledAll="isInReleaseOrReviewStatus"
                    :index="idx"
                  />
                </div>

                <div
                  v-if="
                    optionPlatformList[idx] !== undefined &&
                      optionPlatformList[idx] !== '' &&
                      optionPlatformList[idx] === PLATFORM.PC
                  "
                  class="mt-20 pb-24 flex justify-center"
                >
                  <button
                    type="button"
                    class="h-[3.2rem] inline-flex items-center gap-4 px-16"
                    @click="handleToggleMore(idx)"
                  >
                    <span class="text-md leading-sm font-regular text-on-surface-elevation-3">
                      {{
                        isOpenDetail[idx]
                          ? $t(
                            'studio.prj_prod.this_prod.execution_setting_option_collapse_all_msg'
                          )
                          : $t('studio.prj_prod.this_prod.execution_setting_option_expand_all_msg')
                      }}
                    </span>
                    <s-icon
                      size="xl"
                      icon="ic-v2-control-arrow-down-line"
                      class="text-on-surface-elevation-3 flex"
                      :class="{ 'rotate-180': isOpenDetail[idx] }"
                    />
                  </button>
                </div>
              </div>
            </div>
          </v-field-array>
        </template>
      </form-header-folding>
    </st-box>
    <template v-if="defaultRunOptionPlatform === PLATFORM.PC">
      <st-box class="mt-24">
        <form-header-folding :adminName="$t('studio.prj_prod.this_prod.execution_setting_title')">
          <template #content>
            <launch-installation-folder
              name="installationFolder"
              :disabled="!isProductWritable || isInReleaseOrReviewStatus"
              :desc="$t('studio.prj_prod.this_prod.execution_setting_folder_msg')"
            />
          </template>
        </form-header-folding>
      </st-box>
      <st-box class="mt-24">
        <form-header-folding :adminName="$t('studio.prj_prod.execution_setting_pc_client_title')">
          <template #button>
            <div class="shrink-0 flex gap-8 items-center">
              <s-button variant="outline" size="sm" @click="onPreview">
                {{ $t('studio.prj_prod.execution_setting_pc_client_img_preview_btn') }}
              </s-button>
            </div>
          </template>
          <template #content>
            <div class="flex flex-col gap-16">
              <upload-client-setting
                v-for="{
                  name,
                  label,
                  fileLabel,
                  extensions,
                  dimensions,
                  required,
                  canEdit,
                  isHiddenAddLogo,
                  isGuideShown,
                  stencilBox,
                  disableOpacity,
                  cropBox
                } of pcClientSettings"
                :id="name"
                :key="name"
                :name="name"
                :label="label"
                :fileLabel="fileLabel"
                :extensions="extensions"
                :dimensions="dimensions"
                :stencilBox="
                  stencilBox ? stencilBox : { width: dimensions[0], height: dimensions[1] }
                "
                :cropBox="cropBox ? cropBox : { width: dimensions[0], height: dimensions[1] }"
                :required="required"
                :canEdit="canEdit"
                :disabled="!isProductWritable || isInReleaseOrReviewStatus"
                :showMessageWhenDisabled="isShowPopupWhenDisableImageDeleteButton"
                :isHiddenAddLogo="isHiddenAddLogo"
                :isGuideShown="isGuideShown"
                :disableOpacity="disableOpacity"
              />
            </div>
          </template>
        </form-header-folding>
      </st-box>
    </template>

    <div v-if="!isInReleaseOrReviewStatus" class="mt-40 flex justify-center gap-16">
      <launch-setting-save-button
        :isLived="isReleased"
        :onSubmit="onSubmit"
        :disabled="isInReleaseOrReviewStatus"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { debounce } from 'lodash-es';
import { storeToRefs } from 'pinia';
import {
  type FieldEntry,
  type FormValidationResult,
  useFieldArray,
  useFieldValue
} from 'vee-validate';
import { useForm } from 'vee-validate';
import { computed, nextTick, ref, shallowRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave, useRouter } from 'vue-router';

import {
  checkBuildIsRegisteredByGameIdApi,
  checkDRMAndSDKByGameIdApi,
  deleteBuildAndRatingReViewByProductNoApi,
  deleteInstallationPathAndPcClientSettingsApi,
  deleteRunOptionApi,
  fetchRequiredProgramListApi,
  generateMetaFileApi,
  saveInstallationPathAndPcClientSettingsApi,
  saveRunOptionApi
} from '@/apis/launch-settings.api';
import StBox from '@/components/common/st-box.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import FormHeaderFolding from '@/components/launch-setting/form-header-folding.vue';
import LaunchInstallationFolder from '@/components/launch-setting/launch-installation-folder.vue';
import LaunchOptionPcVrForm from '@/components/launch-setting/launch-option-pc-vr-form.vue';
import MultiLanguageDialog from '@/components/launch-setting/launch-options-multi-language-dialog.vue';
import LaunchSettingPreview from '@/components/launch-setting/launch-setting-preview.vue';
import LaunchSettingSaveButton from '@/components/launch-setting/launch-setting-save-button.vue';
import UploadClientSetting from '@/components/launch-setting/upload-client-setting.vue';
import Dropdown from '@/components/validation/dropdown.vue';
import InputText from '@/components/validation/input-text.vue';
import { useApp } from '@/composables/useApp';
import { showAlert, showConfirm, showDialog } from '@/composables/useDialog';
import { useProduct } from '@/composables/useProduct';
import {
  DEFAULT_LANG_CODE,
  PLATFORM,
  TRANSLATE_LANGUAGES,
  TRANSLATE_LANGUAGES_SETTINGS
} from '@/constants/common.const';
import { COMMON_ERROR_MESSAGE_KEY, COMMON_ERROR_REVIEW_INPROCESS_CANNOT_EDIT, STATUS_CODE } from '@/constants/error.const';
import {
  MAX_LENGTH_INSTALLATION_FOLDER_NAME,
  PLATFORMS_DISPLAY
} from '@/constants/launch-setting.const';
import { URL_REGEX } from '@/constants/regex.const';
import { PRODUCT_BUILD_PAGE_URL } from '@/constants/url.const';
import { Confirmation } from '@/enums/common.enum';
import { LanguageCode } from '@/enums/language-code.enum';
import { RuleNames } from '@/enums/validation.enum';
import { useLaunchSettingStore } from '@/stores/launch-setting.store';
import useProductStore from '@/stores/product.store';
import { useUserStore } from '@/stores/user.store';
import type { ErrorResponse, LanguageModel } from '@/types/common/common.type';
import type { ImageLanguageType } from '@/types/common/file.type';
import type { FormOptionGroup } from '@/types/common/form.type';
import type {
  GameLaunchSettingFormType,
  InstallPathAndPcClientSettings,
  MultiLanguageRunOptionsInfo,
  PcClientImageSettings,
  RequiredProgram,
  RequiredProgramItem,
  RequireProgramGroup,
  RunOption,
  RunOptionFormFieldType,
  RunOptionGroup,
  TranslatedLanguage
} from '@/types/launch-settings/launch-settings.type';
import type {
  CheckDRMAndSDKRequest,
  InstallPathAndPcClientSettingsRequest,
  RunOptionCheckRequest
} from '@/types/launch-settings/launch-settings-request.type';
import type {
  CheckDRMAndSDKResponse,
  DRMAndSDKRunOptionResponse,
  InstallPathAndPcClientSettingsResponse,
  RequiredProgramResponse
} from '@/types/launch-settings/launch-settings-response.type';
import { redirectTo, showCommonErrorDialog } from '@/utils/common.util';
import {
  createGameLaunchSettingFormField,
  createLaunchOption,
  getRequiredProgramsConfig,
  isProductInReleaseOrReviewStatus,
  isProductReleased,
  showAlertPopup,
  showErrorAlert,
  showLiveConfirmDialog
} from '@/utils/launch-settings.util';
import { generateErrorMsg } from '@/utils/validation.util';

const router = useRouter();

interface LaunchGameDemoUltilityProps {
  typeGame: string;
  gameId: string;
  releaseType: string;
  verifyStatus: string;
  planStatus: string;
  installationPathAndPcClientSettings: InstallPathAndPcClientSettingsResponse | undefined;
  runOptions: Array<RunOptionGroup>;
}

const props = defineProps<LaunchGameDemoUltilityProps>();

const emits = defineEmits<{
  onShowExecutionNameForSamePlatform: [v: boolean];
  onUpdatePlatformGuide: [v: Array<string>];
}>();

const { checkProductPermission } = useApp();
const { t } = useI18n();
const SAVE_COMPLETED_MESSAGE_TYPE = {
  RELEASE_GUIDE: 'release_guide',
  COMPLETE_SAVE: 'complete_save'
};
const PLATFORM_PREFIX = 'key';
const MAX_RUN_OPTIONS_NUMBER = 101; // 100 additional run option  + 1 default run option
const platFormDropDownKeys = ref<Array<Record<string, number>>>([]);
const requiredProgramsRef = shallowRef<Array<RequiredProgram>>([]);
const requiredProgramsConfig: Array<RequiredProgram> = getRequiredProgramsConfig();
const formHeaderFoldingComponent = ref();
const defaultRunOptionPlatform = ref<string>('');
const productNo = router.currentRoute.value.params.productId as string;
let translatedRunOptionNames: Array<Record<string, string>> = []; // for multi-language: use to check if runOptionName is changed after translation
let platformSavedRunOptionsWillBeDeleted = Array<RunOptionFormFieldType>();
const MAX_LENGTH_OPTION_NAME = '40';
const MAX_LENGTH_EXECUTION_URL = '500';
const NUM_OF_PLATFORMS_NEED_TO_SHOW_OPTION_NAME = 2;
const platformNumber = ref<number>(0);
const isMeetThePlatformCondition = computed<boolean>(() => {
  if (platformNumber.value >= NUM_OF_PLATFORMS_NEED_TO_SHOW_OPTION_NAME) {
    return true;
  }
  return false;
});

const optionPlatformList = ref<string[]>([]);
const platformsForGuideSection: string[] = [];
const { validate, setFieldError, validateField, meta, resetForm, setFieldValue, values } =
  useForm<GameLaunchSettingFormType>({
    initialValues: createFormFields(props.installationPathAndPcClientSettings, props.runOptions)
  });

const userStore = useUserStore();
const { selectedGroupInfo } = storeToRefs(userStore);

const productStore = useProductStore();
const { productName, isProductWritable } = storeToRefs(productStore);
const launchSettingStore = useLaunchSettingStore();
const { languageOptionName } = storeToRefs(launchSettingStore);

const productComposable = useProduct();
const { showCompleteSaveDialog } = productComposable;

const installationPathAndPcClientSettingId = useFieldValue<string>('id');
const gameLaunchLogo = useFieldValue<ImageLanguageType>('gameLaunchLogo');
const gameLaunchRecommend = useFieldValue<ImageLanguageType>('gameLaunchRecommend');
const isReleased = computed(
  () =>
    isProductReleased(props.releaseType, props.planStatus)
);

const isInReleaseOrReviewStatus = computed(
  () =>
    isProductInReleaseOrReviewStatus(props.releaseType, props.verifyStatus, props.planStatus)
);

const isShowPopupWhenDisableImageDeleteButton = computed(() => {
  if (isInReleaseOrReviewStatus.value) {
    return false;
  }
  return !isProductWritable;
});

const gameLaunchIcoImageFormat = ['ico'];
const gameLaunchLogoImageFormat = ['jpg', 'jpeg', 'png'];
const gameLaunchRecommendImageFormat = ['jpg', 'png'];
const {
  fields: launchOptions,
  push,
  remove
} = useFieldArray<RunOptionFormFieldType>('launchOptions');

const isOpenDetail = ref<boolean[]>([true]);
let isChangePlatformFromPCToOthers = false;
const locale = computed(() => {
  return selectedGroupInfo.value?.languageCd ?? DEFAULT_LANG_CODE;
});

const baseLanguageName = computed(() => {
  const language = TRANSLATE_LANGUAGES_SETTINGS.find(
    (lang: LanguageModel) => lang.langCode === locale.value
  );
  return language?.langTitle;
});

const pcClientSettings: Array<PcClientImageSettings> = [
  {
    label: t('studio.prj_prod.execution_setting_pc_client_launcher_image_title'),
    name: 'gameLaunchIco',
    extensions: gameLaunchIcoImageFormat,
    fileLabel: createImageUploadLabel(gameLaunchIcoImageFormat),
    dimensions: [256, 256],
    required: true,
    canEdit: false,
    isHiddenAddLogo: true,
    isGuideShown: false,
    disableOpacity: false
  },
  {
    label: t('studio.prj_prod.execution_setting_pc_client_logo_image_title'),
    fileLabel: createImageUploadLabel(gameLaunchLogoImageFormat),
    name: 'gameLaunchLogo',
    extensions: gameLaunchLogoImageFormat,
    dimensions: [500, 500],
    stencilBox: {
      width: 380,
      height: 380
    },
    required: true,
    canEdit: true,
    isHiddenAddLogo: true,
    isGuideShown: false,
    disableOpacity: true
  },
  {
    label: t('studio.prj_prod.execution_setting_pc_client_client_wallpaper_image_title'),
    fileLabel: createImageUploadLabel(gameLaunchRecommendImageFormat),
    name: 'gameLaunchRecommend',
    extensions: gameLaunchRecommendImageFormat,
    dimensions: [1950, 630],
    stencilBox: { width: 680, height: 220 },
    required: false,
    canEdit: true,
    isHiddenAddLogo: true,
    isGuideShown: true,
    disableOpacity: true
  }
];

const removeTheBeginningSlash = async (index: number, fieldName: string) => {
  setTimeout(() => {
    const option = launchOptions.value[index];
    if (
      option &&
      option.value &&
      typeof option.value[fieldName] === 'string' &&
      option.value[fieldName].startsWith('/')
    ) {
      setFieldValue(
        `launchOptions.${index}.${fieldName}`,
        option.value[fieldName].replace(/^\/+/g, '')
      );
    }
  }, 0);
};

const unfoldFormHeaderFolding = () => {
  formHeaderFoldingComponent.value?.onOpen();
};

function onUpdatePlatFormGuide(platformsForGuideSection: Array<string>) {
  emits('onUpdatePlatformGuide', platformsForGuideSection);
}

function addPlatformForGuideSection(platform: string, isEmit: boolean = true) {
  if (!platformsForGuideSection.includes(platform)) {
    platformsForGuideSection.push(platform);
    if (isEmit) {
      onUpdatePlatFormGuide([...platformsForGuideSection]);
    }
  }
}
function removePlatformForGuideSection(platform: string, isEmit: boolean = true) {
  if (platform) {
    const platformCount = getPlatformNumByName(platform);
    if (platformCount === 0) {
      const index = platformsForGuideSection.indexOf(platform);
      if (index !== -1) {
        platformsForGuideSection.splice(index, 1);
        if (isEmit) {
          onUpdatePlatFormGuide([...platformsForGuideSection]);
        }
      }
    }
  }
}

function createImageUploadLabel(fileTypes: Array<string>, size: number = 30): string {
  return `${t('studio.common.file_upl_guide1_1', {
    acceptFileExtAttributeLabel: fileTypes.join(', ')
  })} | ${t('studio.common.file_upl_guide1_2', {
    size
  })}`;
}
function getImageUrlInfo(imgUrl: string): string[] {
  const urlInfo: string[] = imgUrl.split('?name=');
  if (urlInfo[1]) {
    urlInfo[1] = decodeURIComponent(urlInfo[1]);
  }
  return urlInfo;
}
function getKeyValue<T extends object, U extends keyof T>(obj: T, key: U) {
  return obj[key];
}
function bindInstallationPathAndPcClientSettingsToFormFields(
  formFields: GameLaunchSettingFormType,
  installationPathAndPcClientSettings?: InstallPathAndPcClientSettings
): void {
  if (installationPathAndPcClientSettings && installationPathAndPcClientSettings.id) {
    formFields.installationFolder = installationPathAndPcClientSettings.installPath;
    formFields.id = installationPathAndPcClientSettings.id;
    const resourcesInfo: Array<Record<string, string>> = [
      { urlName: 'windowsIconUrl', fieldName: 'gameLaunchIco' },
      { urlName: 'productLogoImageUrl', fieldName: 'gameLaunchLogo' },
      { urlName: 'mainRecommendImageUrl', fieldName: 'gameLaunchRecommend' }
    ];
    resourcesInfo.forEach((resource: Record<string, string>) => {
      const imgUrl = getKeyValue(
        installationPathAndPcClientSettings,
        resource.urlName as 'windowsIconUrl' | 'productLogoImageUrl' | 'mainRecommendImageUrl'
      );
      if (imgUrl) {
        const [linkCdn, name]: string[] = getImageUrlInfo(imgUrl);
        const field = getKeyValue(
          formFields,
          resource.fieldName as 'gameLaunchIco' | 'gameLaunchLogo' | 'gameLaunchRecommend'
        );
        field.name = name;
        field.image = linkCdn;
        field.linkCdn = linkCdn;
        field.thumbnail = linkCdn;
      }
    });
  }
}
function buildRunOptionNameMultilingual(
  runOptionNames: Record<string, string>
): Record<string, string> {
  const copyObj = { ...runOptionNames };
  TRANSLATE_LANGUAGES.forEach((lang: LanguageModel) => {
    if (copyObj[lang.langCode] === undefined || copyObj[lang.langCode] === null) {
      copyObj[lang.langCode] = '';
    }
  });
  translatedRunOptionNames.push({ ...copyObj });
  return copyObj;
}
function moveTheDefaultRunOptionToTheFirst(runOptions: Array<RunOptionGroup>): void {
  const defaultRunOptionIndex = runOptions.findIndex(
    (runOption: RunOptionGroup) => runOption.isDefault
  );
  if (defaultRunOptionIndex > 0) {
    const defaultRunOption = runOptions[defaultRunOptionIndex];
    runOptions.splice(defaultRunOptionIndex, 1);
    runOptions.unshift(defaultRunOption);
  }
}
function sortRunOptionsByOrderNo(runOptions: Array<RunOptionGroup>): void {
  runOptions.sort((a: RunOptionGroup, b: RunOptionGroup) => {
    if (isNaN(a.orderNo) || isNaN(b.orderNo)) {
      return 0;
    }
    if (a.orderNo - b.orderNo > 0) {
      return 1;
    } else if (a.orderNo - b.orderNo < 0) {
      return -1;
    }
    return 0;
  });
}
function createRunOptionFormFields(
  runOption: RunOptionGroup,
  index: number
): RunOptionFormFieldType {
  const runOptionFormFields: RunOptionFormFieldType = {
    index,
    id: runOption.id || '',
    platform: runOption.type,
    executionFile: runOption.executable,
    workingDirectory: runOption.workingPath,
    runOptionNames: buildRunOptionNameMultilingual(runOption.runOptionNames),
    argument: runOption.programParams,
    processFile: runOption.processFile || '',
    finalExecutable: runOption.finalExecutable || '',
    serverUse: Confirmation.YES,
    executionUrl: runOption.resources?.webExecuteUrl || '',
    isDefault: runOption.isDefault,
    orderNo: runOption.orderNo || index
  };
  runOption.requiredPrograms.forEach((requiredProgramResponse: RequireProgramGroup) => {
    const len = requiredProgramsConfig.length;
    const { programType, programNames } = requiredProgramResponse;
    for (let i = 0; i < len; i++) {
      const { type, isMultiple } = requiredProgramsConfig[i];
      if (programType === type && programNames.length) {
        if (isMultiple) {
          runOptionFormFields[type] = programNames;
        } else {
          runOptionFormFields[type] = programNames[0];
        }
        break;
      }
    }
  });
  requiredProgramsConfig.forEach((requiredProgram: RequiredProgram) => {
    const { isMultiple } = requiredProgram;
    if (runOptionFormFields[requiredProgram.type] === undefined) {
      if (isMultiple) {
        runOptionFormFields[requiredProgram.type] = [];
      } else {
        runOptionFormFields[requiredProgram.type] = '';
      }
    }
  });
  return runOptionFormFields;
}
function bindRunOptionsToFormFields(
  formFields: GameLaunchSettingFormType,
  runOptions: Array<RunOptionGroup>
): void {
  if (Array.isArray(runOptions) && runOptions.length) {
    if (runOptions.length > 1) {
      sortRunOptionsByOrderNo(runOptions);
      moveTheDefaultRunOptionToTheFirst(runOptions);
    }
    runOptions.forEach((runOption: RunOptionGroup, index: number) => {
      addPlatformForGuideSection(runOption.type, false);
      platformNumber.value++;
      if (runOption.isDefault) {
        setDefaultPlatform(runOption.type);
      }
      const runOptionFormFields: RunOptionFormFieldType = createRunOptionFormFields(
        runOption,
        index
      );
      if (defaultRunOptionPlatform.value && runOption.type !== defaultRunOptionPlatform.value) {
        addPlatformSavedRunOptionsWillBeDeleted(runOptionFormFields);
      }
      optionPlatformList.value.push(runOption.type);
      formFields.launchOptions.push(runOptionFormFields);
    });
  }
}
function createFormFields(
  installationPathAndPcClientSettings: InstallPathAndPcClientSettings | undefined,
  runOptions: Array<RunOptionGroup>
): GameLaunchSettingFormType {
  const formFields = createGameLaunchSettingFormField();
  bindInstallationPathAndPcClientSettingsToFormFields(
    formFields,
    installationPathAndPcClientSettings
  );
  bindRunOptionsToFormFields(formFields, runOptions);
  if (!formFields.launchOptions.length) {
    formFields.launchOptions.push(createLaunchOption(0, true));
  }
  formFields.launchOptions.forEach(() => {
    addKeyToPlatFormDropdownKeys();
  });
  if (isMeetThePlatformCondition.value) {
    onShowExecutionNameForSamePlatform(true);
  }
  return formFields;
}
function removeItemFromOptionPlatformList(index: number) {
  optionPlatformList.value.splice(index, 1);
}
function removePlatformDropdownKey(index: number) {
  platFormDropDownKeys.value.splice(index, 1);
}

function addItemToOptionPlatformList(platform: string) {
  optionPlatformList.value.push(platform);
}
function addKeyToPlatFormDropdownKeys() {
  platFormDropDownKeys.value.push({ [PLATFORM_PREFIX]: 0 });
}

const onPushLaunch = debounce(async () => {
  if (!(await checkProductPermission())) {
    return;
  }
  // adds a new item to the array
  if (launchOptions.value.length >= MAX_RUN_OPTIONS_NUMBER) {
    showAlertPopup(t('studio.prj_prod.this_prod.execution_setting_option_add_limit_popup'));
    return;
  }
  const len = launchOptions.value.length;
  const isDefault = len === 0;
  push(createLaunchOption(len, isDefault));
  addItemToOptionPlatformList('');
  addKeyToPlatFormDropdownKeys();
  isOpenDetail.value.push(false);
  unfoldFormHeaderFolding();
}, 150);

const handleToggleMore = (index: number) => {
  isOpenDetail.value[index] = !isOpenDetail.value[index];
};
function setDefaultPlatform(platform: string) {
  defaultRunOptionPlatform.value = platform;
}

function getDefaultPlatform(): string {
  return defaultRunOptionPlatform.value;
}
const resetWhenPlatformIsChanged = async (optionIndex: number, newPlatform: string) => {
  const optionId = launchOptions.value[optionIndex].value.id;
  const isDefault = launchOptions.value[optionIndex].value.isDefault;
  const option = createLaunchOption(optionIndex, isDefault);
  option.id = optionId;
  option.platform = newPlatform;
  setFieldValue(`launchOptions.${optionIndex}`, option);
  await nextTick();
  if (isDefault && newPlatform !== PLATFORM.PC) {
    resetInstallationPathAndPcClientSettingData();
  }
};
function resetPlatformSavedRunOptionsWillBeDeleted() {
  platformSavedRunOptionsWillBeDeleted = [];
}
function addPlatformSavedRunOptionsWillBeDeleted(runOpion: RunOptionFormFieldType) {
  platformSavedRunOptionsWillBeDeleted.push(runOpion);
}
function resetData() {
  platformSavedRunOptionsWillBeDeleted = [];
  isChangePlatformFromPCToOthers = false;
}
function addAllPlatformSavedRunOptionsWillBeDeleted(platForm: string) {
  const runOptionsLength = launchOptions.value.length;
  for (let i = 0; i < runOptionsLength; i++) {
    const runOption = launchOptions.value[i].value;
    if (!runOption.isDefault && runOption.id && runOption.platform === platForm) {
      addPlatformSavedRunOptionsWillBeDeleted(launchOptions.value[i].value);
    }
  }
}
function setInstallationPathAndPcClientSettingId(id: string) {
  setFieldValue('id', id);
}
function redirectToNewTab(url: string) {
  redirectTo(`/${locale.value}${url}`, { external: true, open: { target: '_blank' } });
}
function removeAllPlfatformRunOptionsFromForm(platForm: string) {
  let runOptionsLength = launchOptions.value.length;
  for (let i = 0; i < runOptionsLength; i++) {
    if (
      !launchOptions.value[i].value.isDefault &&
      launchOptions.value[i].value.platform === platForm
    ) {
      removeRunOptionFromUI(i);
      i--;
      runOptionsLength--;
    }
  }
}
async function showConfirmDialog(content: string, confirmLabel: string) {
  const result = await showConfirm({
    content,
    confirmLabel,
    cancelLabel: t('studio.common.popup_case_close_btn'),
    cancelVariant: 'outline'
  });
  return result;
}
async function removeAllSavedRunOptionsWhenChangePlatform() {
  const runOptionsLength = platformSavedRunOptionsWillBeDeleted.length;
  const apis: Array<Promise<boolean>> = [];
  for (let i = 0; i < runOptionsLength; i++) {
    apis.push(deleteRunOptionApi(props.gameId, platformSavedRunOptionsWillBeDeleted[i].id));
  }
  await Promise.all(apis);
  resetPlatformSavedRunOptionsWillBeDeleted();
}

async function deleteRegisteredBuildsAndSelfReviewRatings(productNo: number) {
  await deleteBuildAndRatingReViewByProductNoApi(productNo, props.gameId);
}

const handleUpdatePlatform = async (newPlatForm: string, index: number) => {
  const currentPlatform = optionPlatformList.value.at(index);
  if (currentPlatform === newPlatForm) {
    return;
  }
  const runOption = launchOptions.value[index].value;
  const runOptionId = runOption.id;
  if (runOption.isDefault && currentPlatform) {
    let result = true;
    if (currentPlatform === PLATFORM.PC) {
      result = await showConfirm({
        content: t('studio.prj_prod.this_prod.execution_setting.option_change_guide'),
        confirmLabel: t('studio.common.popup_case_change_short_btn'),
        cancelLabel: t('studio.common.popup_case_cancel_btn'),
        confirmVariant: 'red',
        cancelVariant: 'outline'
      });
    } else {
      showAlertPopup(t('studio.prj_prod.this_prod.execution_setting.option_change_to_pc_guide'));
    }
    if (!result) {
      setFieldValue(`launchOptions.${index}.platform`, currentPlatform);
      platFormDropDownKeys.value[index][PLATFORM_PREFIX]++;
      setDefaultPlatform(currentPlatform);
      return;
    }
    platformNumber.value = 0;
  }
  optionPlatformList.value[index] = newPlatForm;
  resetWhenPlatformIsChanged(index, newPlatForm);
  if (launchOptions.value[index].value.isDefault) {
    setDefaultPlatform(newPlatForm);
  }
  platformNumber.value++;
  if (runOption.isDefault && currentPlatform) {
    if (newPlatForm !== PLATFORM.PC) {
      isChangePlatformFromPCToOthers = true;
    }
    if (runOptionId) {
      addAllPlatformSavedRunOptionsWillBeDeleted(currentPlatform);
    }
    removeAllPlfatformRunOptionsFromForm(currentPlatform);
  }
  if (currentPlatform) {
    removePlatformForGuideSection(currentPlatform, false);
  }
  addPlatformForGuideSection(newPlatForm, false);
  onUpdatePlatFormGuide([...platformsForGuideSection]);
};
const updateRunOptionIndex = () => {
  launchOptions.value.forEach((item: FieldEntry<RunOptionFormFieldType>, index: number) => {
    setFieldValue(`launchOptions.${index}.index`, index);
    item.value.index = index;
  });
};
const removeShowMoreButtonForDeletedRunOption = (deletedRunOptionIndex: number) => {
  isOpenDetail.value = isOpenDetail.value.filter((_: boolean, idx: number) => idx !== deletedRunOptionIndex);
};
const removeRunOptionFromUI = (index: number) => {
  remove(index);
  removeItemFromOptionPlatformList(index);
  removePlatformDropdownKey(index);
  removeShowMoreButtonForDeletedRunOption(index);
  updateRunOptionIndex();
};
const allowToDeleteOption = (optionIndex: number): boolean => {
  if (launchOptions.value[optionIndex].value.isDefault) {
    return false;
  }
  if (!launchOptions.value[optionIndex].value.id) {
    return true;
  }
  const len = launchOptions.value.length;
  for (let i = 0; i < len; i++) {
    if (i !== optionIndex && launchOptions.value[i].value.id) {
      return true;
    }
  }
  return false;
};

function clearRunOptionNames(index: number) {
  if (launchOptions.value.length > 0) {
    const runOption = launchOptions.value[index];
    if (runOption && runOption.value && runOption.value.runOptionNames) {
      const runOptionNames = runOption.value.runOptionNames;
      for (const langCode in runOptionNames) {
        setFieldValue(`launchOptions.${index}.runOptionNames.${langCode}`, '');
        if (translatedRunOptionNames[index]) {
          translatedRunOptionNames[index][langCode] = '';
        }
      }
    }
  }
}

async function doDeleteRunOption(index: number, runOptionId: string) {
  try {
    let isDeleted = true;
    if (runOptionId) {
      isDeleted = await deleteRunOptionApi(props.gameId, runOptionId);
    }
    if (isDeleted) {
      const platForm = launchOptions.value[index].value.platform;
      removeRunOptionFromUI(index);

      if (platForm) {
        platformNumber.value--;
        if (platformNumber.value === 1) {
          clearRunOptionNames(0);
        }
      }
      removePlatformForGuideSection(platForm);
      checkDuplicateAllOptionName();
    }
  } catch (errorInfo) {
    const error = errorInfo as ErrorResponse;
    const errorCode = error.statusCode ?? 0;

    if (errorCode === STATUS_CODE.GROUP_MIGRATION_ALREADY_PROGRESS || errorCode === STATUS_CODE.PROJECT_MIGRATION_ALREADY_PROGRESS) {
      return;
    }
    if (errorCode === STATUS_CODE.PRODUCT_RELEASE_ALREADY_EXIST || errorCode === STATUS_CODE.RATING_BUILD_OPTION_NOT_ACCEPTABLE) {
      showCommonErrorDialog(COMMON_ERROR_REVIEW_INPROCESS_CANNOT_EDIT, () => window.location.reload());
      return;
    }
    showCommonErrorDialog(COMMON_ERROR_MESSAGE_KEY);
  }
}
const handleRemoveItem = async (index: number) => {
  const isAllowToDelete = allowToDeleteOption(index);
  if (!isAllowToDelete) {
    return;
  }
  const runOptionId: string = launchOptions.value[index].value.id;
  if (runOptionId && !isProductWritable.value) {
    return;
  }
  const res = await showAlert({
    content: t('studio.prj_prod.execution_setting_option_delete_pop_msg'),
    confirmVariant: 'red',
    isCancelButtonVisible: true,
    cancelVariant: 'outline',
    confirmLabel: t('studio.prj_prod.execution_setting_option_delete_cf_btn')
  });
  if (res) {
    doDeleteRunOption(index, runOptionId);
  }
};

const setRunOptionsId = (ids: Array<string>) => {
  if (ids.length > 0) {
    for (let i = 0; i < ids.length; i++) {
      if (!launchOptions.value[i].value.id) {
        setFieldValue(`launchOptions.${i}.id`, ids[i]);
      }
    }
  }
};
const checkFormError = async (): Promise<boolean> => {
  let hasFormError = false;
  const validateResult: FormValidationResult<GameLaunchSettingFormType, GameLaunchSettingFormType> =
    await validate();
  const checkErrorFactory: Array<Function> = [];
  if (isMeetThePlatformCondition.value) {
    checkErrorFactory.push(checkEmptyAllOptionName, checkDuplicateOptionNameWhenSubmitForm);
  }
  for (let i = 0; i < checkErrorFactory.length; i++) {
    const func = checkErrorFactory[i];
    const hasError = func();
    if (hasError || !meta.value.valid) {
      hasFormError = true;
    }
  }
  if (hasFormError || !validateResult.valid) {
    return true;
  }
  return false;
};

const showDRMAndSDKPopup = async (messageKey: string) => {
  const result = await showConfirmDialog(t(messageKey), t('studio.build_mgmt_pg_redirect_btn'));
  if (result) {
    redirectToNewTab(PRODUCT_BUILD_PAGE_URL);
  }
};

const hasDRMAndPCSDKError = async () => {
  const res = await checkDRMAndPCSDK();
  if (!res) {
    showErrorAlert();
    return true;
  }
  if (typeof res === 'string') {
    showAlertPopup(res);
    return true;
  }
  if (!res.isSdkApplied) {
    const errorMessage = generateErrorMsg(
      'studio.execution_setting_option.exe_file_invalid_msg',
      RuleNames.CHECK_DUPLICATE
    );
    if (!res.isUseDrm) {
      const len = launchOptions.value.length;
      for (let i = 0; i < len; i++) {
        if (launchOptions.value[i].value.finalExecutable) {
          setFieldError(`launchOptions.${i}.finalExecutable`, errorMessage);
        } else {
          setFieldError(`launchOptions.${i}.executionFile`, errorMessage);
        }
      }
      showDRMAndSDKPopup('studio.post_release_exe_opt_update.invalid_exe_file_msg');
      return true;
    } else if (Array.isArray(res.runOptions)) {
      const len = res.runOptions.length;
      const errorRunOptionIndexes: Array<{ index: number; messageKey: string }> = [];
      for (let i = 0; i < len; i++) {
        const drmRunOption: DRMAndSDKRunOptionResponse = res.runOptions[i];
        if (!drmRunOption.exist) {
          errorRunOptionIndexes.push({
            index: i,
            messageKey: 'studio.post_release_exe_opt_update.invalid_exe_file_msg'
          });
        } else if (!drmRunOption.drmApplied) {
          errorRunOptionIndexes.push({
            index: i,
            messageKey: 'studio.post_release_exe_opt_update.drm_not_applied_msg'
          });
        }
      }
      if (errorRunOptionIndexes.length > 0) {
        errorRunOptionIndexes.forEach(
          (errorRunOptionIndex: { index: number; messageKey: string }) => {
            const { index, messageKey } = errorRunOptionIndex;
            if (launchOptions.value[index].value.finalExecutable) {
              setFieldError(`launchOptions.${index}.finalExecutable`, errorMessage);
            } else {
              setFieldError(`launchOptions.${index}.executionFile`, errorMessage);
            }
            showDRMAndSDKPopup(messageKey);
          }
        );
        return true;
      }
    }
  }

  return false;
};

const submitFormData = async () => {
  try {
    const hasFormError = await checkFormError();
    if (hasFormError) {
      return;
    }
    if (isMeetThePlatformCondition.value) {
      const hasUntranslatedOptionName = checkOptionNameTranslation();
      if (hasUntranslatedOptionName) {
        await showAlertPopup(t('studio.common.popup_case_ai_not_entered'));
        onSettingMultiLanguage();
        return;
      }
    }
    let saveCompletedMessageType = isReleased.value
      ? SAVE_COMPLETED_MESSAGE_TYPE.RELEASE_GUIDE
      : SAVE_COMPLETED_MESSAGE_TYPE.COMPLETE_SAVE;
    if (isMeetThePlatformCondition.value) {
      const isOptionNameChanged = hasOptionNameChanged();
      if (isOptionNameChanged) {
        const result = await showConfirm({
          content: t('studio.common.popup_case_ai_modified'),
          confirmLabel: t('studio.common.popup_case_save_btn'),
          cancelVariant: 'outline'
        });
        if (!result) {
          return;
        } else {
          saveCompletedMessageType = SAVE_COMPLETED_MESSAGE_TYPE.COMPLETE_SAVE;
        }
      }
    }
    let allowSave = true;
    if (isReleased.value) {
      if (meta.value.dirty) {
        allowSave = await showLiveConfirmDialog();
      } else {
        await showAlertPopup(t('studio.common.popup_case_v_release_guide'));
        allowSave = false;
      }
    }
    if (allowSave) {
      if (isReleased.value && getDefaultPlatform() === PLATFORM.PC) {
        const hasError = await hasDRMAndPCSDKError();
        if (hasError) {
          return;
        }
      }
      await removeAllSavedRunOptionsWhenChangePlatform();
      if (isChangePlatformFromPCToOthers && productNo && !isNaN(Number(productNo))) {
        await deleteRegisteredBuildsAndSelfReviewRatings(Number(productNo));
        if (isInstallationPathAndPcClientSettingSaved()) {
          await deleteInstallationPathAndPcClientSettingsApi(
            installationPathAndPcClientSettingId.value,
            props.gameId
          );
          setInstallationPathAndPcClientSettingId('');
          resetInstallationPathAndPcClientSettingData();
        }
      }
      const apis = [];
      if (getDefaultPlatform() === PLATFORM.PC) {
        apis.push(handleSaveInstallationPathAndPcClientSettings(values));
      }
      apis.push(...getRunOptionApiList(values));
      if (!apis.length) {
        return;
      }
      const results = await Promise.all(apis);
      if (getDefaultPlatform() === PLATFORM.PC) {
        // set id for Pc run option settings
        if (!values.id && results[0]) {
          setInstallationPathAndPcClientSettingId(results[0]);
        }
        results.shift(); // remove result of installation path and pc client settings
      }
      setRunOptionsId(results);
      resetForm({ values });
      resetData();
      if (isReleased.value) {
        generateMetaFileApi(props.gameId);
      }
      await productStore.getProductPlatform();
      if (getDefaultPlatform() === PLATFORM.PC) {
        const res = await checkBuildIsRegisteredByGameIdApi(props.gameId);
        if (res && !res.isRegistered) {
          const result = await showConfirmDialog(
            t('studio.prj_prod.this_prod.execution_setting.build_not_uploaded_msg'),
            t('studio.prj_prod.build_upload_pg_dir_btn')
          );
          if (result) {
            redirectToNewTab(PRODUCT_BUILD_PAGE_URL);
            return;
          }
        }
      }
      if (saveCompletedMessageType === SAVE_COMPLETED_MESSAGE_TYPE.RELEASE_GUIDE) {
        await showAlertPopup(t('studio.common.popup_case_iv_release_guide'));
      } else {
        showCompleteSaveDialog();
      }
    }
  } catch (errorInfo) {
    const error = errorInfo as ErrorResponse;
    const errorCode = error.statusCode ?? 0;

    if (errorCode === STATUS_CODE.GROUP_MIGRATION_ALREADY_PROGRESS || errorCode === STATUS_CODE.PROJECT_MIGRATION_ALREADY_PROGRESS) {
      return;
    }
    if (errorCode === STATUS_CODE.PRODUCT_RELEASE_ALREADY_EXIST || errorCode === STATUS_CODE.RATING_BUILD_OPTION_NOT_ACCEPTABLE) {
      showCommonErrorDialog(COMMON_ERROR_REVIEW_INPROCESS_CANNOT_EDIT, () => window.location.reload());
      return;
    }
    showCommonErrorDialog(COMMON_ERROR_MESSAGE_KEY);
  }
};
const onSubmit = async () => {
  if (!(await checkProductPermission())) {
    return;
  }
  submitFormData();
};
function createImageUrl(url: string, name: string): string {
  return url ? `${url}?name=${encodeURIComponent(name)}` : '';
}
const handleSaveInstallationPathAndPcClientSettings = async (
  values: GameLaunchSettingFormType
): Promise<string> => {
  const payload: InstallPathAndPcClientSettingsRequest = {
    gameId: props.gameId,
    installPath: values.installationFolder,
    dlcAutoInstall: false,
    windowsIconUrl: createImageUrl(values.gameLaunchIco.linkCdn, values.gameLaunchIco.name),
    productLogoImageUrl: createImageUrl(values.gameLaunchLogo.linkCdn, values.gameLaunchLogo.name),
    mainRecommendImageUrl: createImageUrl(
      values.gameLaunchRecommend.linkCdn,
      values.gameLaunchRecommend.name
    )
  };
  if (values.id) {
    payload.id = values.id;
  }
  const id = await saveInstallationPathAndPcClientSettingsApi(payload);
  return id;
};
const mapRequiredPrograms = (
  runOptionField: RunOptionFormFieldType
): Array<RequiredProgramItem> => {
  const requiredPrograms: Array<RequiredProgramItem> = [];
  requiredProgramsConfig.forEach((requireProgramConfig: RequiredProgram) => {
    const { type } = requireProgramConfig;
    const requireProgramNames = runOptionField[type];
    if (requireProgramNames) {
      if (Array.isArray(requireProgramNames) && requireProgramNames.length) {
        requireProgramNames.forEach((requireProgramName: string) => {
          requiredPrograms.push({
            programType: type,
            programName: requireProgramName
          });
        });
      } else if (typeof requireProgramNames === 'string') {
        requiredPrograms.push({
          programType: type,
          programName: requireProgramNames
        });
      }
    }
  });
  return requiredPrograms;
};
const buildRunOptionPayloadList = (
  runOptionFields: Array<RunOptionFormFieldType>
): Array<RunOption> => {
  const runOptions: Array<RunOption> = [];
  runOptionFields.forEach((runOptionField: RunOptionFormFieldType, index: number) => {
    if (runOptionField.platform) {
      const resources = {
        webExecuteUrl: runOptionField.executionUrl
      };
      const requiredPrograms = mapRequiredPrograms(runOptionField);
      const runOption: RunOption = {
        gameId: props.gameId,
        processFile: runOptionField.processFile,
        runOptionNames: runOptionField.runOptionNames,
        executable: runOptionField.executionFile,
        finalExecutable: runOptionField.finalExecutable,
        type: runOptionField.platform,
        isDefault: runOptionField.isDefault,
        workingPath: runOptionField.workingDirectory,
        programParams: runOptionField.argument,
        requiredPrograms,
        resources,
        orderNo: index
      };
      if (runOptionField.id) {
        runOption.id = runOptionField.id;
      }
      runOptions.push(runOption);
    }
  });
  return runOptions;
};
const getRunOptionApiList = (values: GameLaunchSettingFormType): Array<Promise<string>> => {
  const runOptionPayloadList = buildRunOptionPayloadList(values.launchOptions);
  const apiList: Array<Promise<any>> = runOptionPayloadList.map((payload: RunOption) => {
    return saveRunOptionApi(payload);
  });
  return apiList;
};

const checkIsThereAnyEmptyOptionName = (): boolean => {
  const result = launchOptions.value.some(
    (item: FieldEntry<RunOptionFormFieldType>) => !item.value.runOptionNames[locale.value]
  );
  return result;
};

const onSettingMultiLanguage = async () => {
  unfoldFormHeaderFolding();
  const hasEmptyOptionName = checkIsThereAnyEmptyOptionName();
  if (hasEmptyOptionName) {
    await showAlertPopup(
      t('studio.common.popup_case_lang_setting_input_required', {
        reqValue: t('studio.prj_prod.this_prod.execution_setting_option_name')
      })
    );
    return;
  }
  const sourceLangName = Object.keys(LanguageCode).find(
    (key: string) => LanguageCode[key as keyof typeof LanguageCode] === locale.value
  );
  const runOptionNames: Array<string> = [];
  const content: Array<MultiLanguageRunOptionsInfo> = launchOptions.value.map(
    (item: FieldEntry<RunOptionFormFieldType>) => {
      runOptionNames.push(item.value.runOptionNames[locale.value]);
      return {
        runOptionNames: item.value.runOptionNames,
        platForm: item.value.platform,
        index: item.value.index || 0
      };
    }
  );

  languageOptionName.value = TRANSLATE_LANGUAGES.map((lang: LanguageModel) => {
    return {
      runOptionNames,
      langCode: lang.langCode,
      default: lang.langCode === locale.value
    };
  });

  const translations: Array<TranslatedLanguage> = await showDialog<Array<TranslatedLanguage>>({
    component: shallowRef(MultiLanguageDialog),
    props: {
      content,
      sourceLang: {
        name: sourceLangName,
        value: locale.value
      },
      maxLenOptionName: MAX_LENGTH_OPTION_NAME,
      platformNumber: platformNumber.value
    },
    severity: 'info'
  });
  bindTranslatedOptionName(translations);
};

function checkOptionNameTranslation(): boolean {
  const len = launchOptions.value.length;
  let hasUntranslatedOptionName = false;
  for (let i = 0; i < len; i++) {
    const platForm = launchOptions.value[i].value.platform;
    if (platForm && isMeetThePlatformCondition.value) {
      const runOptionNames = launchOptions.value[i].value.runOptionNames;
      for (const value of Object.values(runOptionNames)) {
        if (value === null || value === undefined || value.trim() === '') {
          hasUntranslatedOptionName = true;
          break;
        }
      }
      if (hasUntranslatedOptionName) {
        break;
      }
    }
  }
  return hasUntranslatedOptionName;
}

function getPlatformNumByName(platform: string): number {
  let count = 0;
  optionPlatformList.value.forEach((item: string) => {
    if (item === platform) {
      count++;
    }
  });
  return count;
}

function hasOptionNameChanged(): boolean {
  if (
    translatedRunOptionNames.length > 0 &&
    launchOptions.value.length === translatedRunOptionNames.length
  ) {
    const len = launchOptions.value.length;
    for (let i = 0; i < len; i++) {
      const runOptionNames = launchOptions.value[i].value.runOptionNames;
      if (runOptionNames[locale.value] !== translatedRunOptionNames[i][locale.value]) {
        return true;
      }
    }
  }
  return false;
}

async function checkDRMAndPCSDK(): Promise<CheckDRMAndSDKResponse | undefined> {
  const runOptionsData: Array<RunOptionCheckRequest> = [];
  launchOptions.value.forEach((item: FieldEntry<RunOptionFormFieldType>) => {
    if (item.value.platform === PLATFORM.PC) {
      const runOptionCheckRequest: RunOptionCheckRequest = {
        id: item.value.id,
        type: item.value.platform,
        executable: item.value.executionFile
      };
      if (item.value.finalExecutable) {
        runOptionCheckRequest.finalExecutable = item.value.finalExecutable;
      }
      runOptionsData.push(runOptionCheckRequest);
    }
  });
  const payload: CheckDRMAndSDKRequest = {
    gameId: props.gameId,
    runOptionCheckRequests: runOptionsData
  };
  return await checkDRMAndSDKByGameIdApi(payload);
}

function bindTranslatedOptionName(translations: Array<TranslatedLanguage>) {
  if (translations) {
    const len = launchOptions.value.length;
    translatedRunOptionNames = [];
    for (let i = 0; i < len; i++) {
      translatedRunOptionNames.push({});
    }
    translations.forEach((translation: TranslatedLanguage) => {
      const { langCode, runOptionNames } = translation;
      launchOptions.value.forEach((option: FieldEntry<RunOptionFormFieldType>, index: number) => {
        option.value.runOptionNames[langCode] = runOptionNames[index];
        translatedRunOptionNames[index][langCode] = runOptionNames[index];
      });
    });
  }
}

function isInstallationPathAndPcClientSettingSaved() {
  return !!installationPathAndPcClientSettingId.value;
}
function resetInstallationPathAndPcClientSettingData() {
  setFieldError('installationFolder', undefined);
  setFieldError('gameLaunchIco', undefined);
  setFieldError('gameLaunchLogo', undefined);
  setFieldError('gameLaunchRecommend', undefined);
}

const onPreview = async () => {
  await showDialog({
    component: shallowRef(LaunchSettingPreview),
    props: {
      productName,
      gameLaunchLogo: gameLaunchLogo.value?.linkCdn,
      gameLaunchRecommend: gameLaunchRecommend.value?.linkCdn
    }
  });
};

function onShowExecutionNameForSamePlatform(isPcShown: boolean) {
  emits('onShowExecutionNameForSamePlatform', isPcShown);
}

const checkDuplicateAllOptionName = () => {
  const len = launchOptions.value.length;
  for (let i = 0; i < len; i++) {
    const option = launchOptions.value[i];
    if (option.value.runOptionNames) {
      checkDuplicatedRunOptionName(option.value.runOptionNames[locale.value], i);
    }
  }
};

const checkDuplicateOptionNameWhenSubmitForm = () => {
  const len = launchOptions.value.length;
  const message = generateErrorMsg(
    'studio.prj_prod.this_prod.execution_setting_option_name_duplicate_msg',
    RuleNames.CHECK_DUPLICATE
  );
  let isDuplicated = false;
  for (let i = 0; i < len; i++) {
    const option = launchOptions.value[i];
    if (option.value.runOptionNames) {
      const originRunOptionName: string = option.value.runOptionNames[locale.value];
      let hasError = false;
      for (let j = 0; j < len; j++) {
        if (j !== i) {
          const runOptionNames = launchOptions.value[j].value.runOptionNames;
          if (
            originRunOptionName &&
            originRunOptionName.trim() !== '' &&
            runOptionNames &&
            runOptionNames[locale.value] === originRunOptionName
          ) {
            setFieldError(`launchOptions.${j}.runOptionNames.${locale.value}`, message);
            hasError = true;
          }
        }
      }
      if (hasError) {
        isDuplicated = true;
        setFieldError(`launchOptions.${i}.runOptionNames.${locale.value}`, message);
      }
    }
  }
  return isDuplicated;
};
const checkEmptyAllOptionName = () => {
  const len = launchOptions.value.length;
  const message = generateErrorMsg(
    'studio.prj_prod.this_prod.execution_setting_option_name_req_msg',
    RuleNames.REQUIRED
  );
  let hasError = false;
  for (let i = 0; i < len; i++) {
    const option = launchOptions.value[i];
    if (option.value.runOptionNames && option.value.runOptionNames[locale.value] === '') {
      setFieldError(`launchOptions.${i}.runOptionNames.${locale.value}`, message);
      hasError = true;
    }
  }
  return hasError;
};
const delayAndCheckDuplicatedOptionName = async (runOptionIndex: number) => {
  setTimeout(() => {
    const runOptionName: string =
    launchOptions.value[runOptionIndex].value.runOptionNames[locale.value];
    checkDuplicatedRunOptionName(runOptionName, runOptionIndex);
  }, 0);
};
const checkDuplicatedRunOptionName = (runOptionName: string, runOptionIndex: number) => {
  if (!isMeetThePlatformCondition.value) {
    return false;
  }
  const len = launchOptions.value.length;
  let hasError = false;
  const message = generateErrorMsg(
    'studio.prj_prod.this_prod.execution_setting_option_name_duplicate_msg',
    RuleNames.CHECK_DUPLICATE
  );
  for (let i = 0; i < len; i++) {
    const option = launchOptions.value[i];
    if (i !== runOptionIndex) {
      if (
        runOptionName &&
        runOptionName.trim() !== '' &&
        option.value.runOptionNames &&
        option.value.runOptionNames[locale.value] === runOptionName
      ) {
        setFieldError(`launchOptions.${i}.runOptionNames.${locale.value}`, message);
        hasError = true;
      }
    }
  }
  if (hasError) {
    setFieldError(`launchOptions.${runOptionIndex}.runOptionNames.${locale.value}`, message);
  } else if (runOptionName && runOptionName.trim() !== '') {
    for (let i = 0; i < len; i++) {
      const option = launchOptions.value[i];
      setFieldError(`launchOptions.${i}.runOptionNames.${locale.value}`, undefined);
      const runOptionNames = option.value.runOptionNames;
      if (
        runOptionNames &&
        typeof runOptionNames[locale.value] === 'string' &&
        runOptionNames[locale.value].trim() !== ''
      ) {
        validateField(`launchOptions.${i}.runOptionNames.${locale.value}`);
      }
    }
  }
};

watch(platformNumber, () => {
  onShowExecutionNameForSamePlatform(isMeetThePlatformCondition.value);
});

const filterRequiredPrograms = (requireProgramsData: Array<RequiredProgramResponse>) => {
  for (let i = 0; i < requiredProgramsConfig.length; i++) {
    const requiredProgram = requiredProgramsConfig[i];
    const type = requiredProgram.type;
    const items = requireProgramsData.filter(
      (item: RequiredProgramResponse) => item.programType === type
    );
    items.sort(
      (item1: RequiredProgramResponse, item2: RequiredProgramResponse) =>
        item1.orderNo - item2.orderNo
    );
    if (Array.isArray(requiredProgram.items) && items.length) {
      const requiredProgramItems = requiredProgram.items as Array<FormOptionGroup>;
      if (!requiredProgram.isMultiple) {
        requiredProgramItems.push({
          label: t('studio.prj_prod.execution_setting_option_requirements_cpp_place_holder'),
          value: ''
        });
      }

      items.forEach(({ programName }: RequiredProgramResponse) => {
        requiredProgramItems.push({
          label: programName,
          value: programName
        });
      });
    }
  }
};
const buildRequiredPrograms = async () => {
  const results = await fetchRequiredProgramListApi();
  if (Array.isArray(results)) {
    filterRequiredPrograms(results);
    requiredProgramsRef.value = requiredProgramsConfig;
  }
};
const init = async () => {
  buildRequiredPrograms();
  onUpdatePlatFormGuide([...platformsForGuideSection]);
};
onBeforeRouteLeave(async () => {
  // if (meta.value.dirty) {
  //   const result = await showConfirm({
  //     content: t('studio.common.popup_case_a_without_saving'),
  //     confirmLabel: t('studio.common.popup_case_a_without_saving_exit_btn'),
  //     cancelVariant: 'outline'
  //   });
  //   if (!result) {
  //     return false;
  //   }
  // }
});
init();
</script>
