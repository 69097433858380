import { defineStore } from 'pinia';
import { ref } from 'vue';

import type { TranslatedLanguage } from '@/types/launch-settings/launch-settings.type';

export const useLaunchSettingStore = defineStore('launchSettingStore', () => {
  const languageOptionName = ref<TranslatedLanguage[]>([]);

  return {
    languageOptionName
  };
});
