<template>
  <right-wing class="relative">
    <div ref="guideContainer" class="w-full">
      <right-wing-item
        :rightWingTitle="$t('studio.member_manage.role_manage_list_by_menu_exe_setting')"
        numberValue="01"
      >
        <p>{{ $t('studio.prj_prod.execution_install_new_guide_main_des') }}</p>
        <div class="flex flex-col gap-8 mt-4">
          <p>
            <span class="font-bold text-on-surface-elevation-2 mr-[0.2rem]">
              {{ $t('studio.prj_prod.execution_install_new_guide_sub_des1_1') }} -
            </span>
            <safe-html :html="$t('studio.prj_prod.execution_install_new_guide_sub_des1_2')" />
          </p>
          <p>
            <span class="font-bold text-on-surface-elevation-2 mr-[0.2rem]">
              {{ $t('studio.prj_prod.execution_install_new_guide_sub_des2_1') }} -
            </span>
            <safe-html :html="$t('studio.prj_prod.execution_install_new_guide_sub_des2_2')" />
          </p>
        </div>
      </right-wing-item>
      <right-wing-item v-if="platFormsGuide.length" numberValue="02" class="mt-24">
        <template v-for="item in platFormsGuide" :key="item">
          <pc-guide
            v-if="item === PLATFORM.PC"
            :key="item"
            :isShowPcExecutionName="isShowPcExecutionName"
          />
          <web-guide v-if="item === PLATFORM.WEB" :key="item" />
        </template>
      </right-wing-item>
      <div v-show="platFormsGuide.includes(PLATFORM.PC)">
        <right-wing-item
          :rightWingTitle="$t('studio.prj_prod.execution_install_set_enabled_guide1')"
          numberValue="03"
          class="mt-24"
        >
          <s-text as="span" :role="stextRole" :class="stextClass">
            {{ $t('studio.prj_prod.execution_install_new_guide_set_main_title1') }}
            <ul :class="itemContainerCssClass">
              <safe-html
                :html="$t('studio.prj_prod.execution_install_new_guide_set_sub_des1')"
                :class="itemCssClass"
                tag="li"
              />
              <safe-html
                :html="$t('studio.prj_prod.execution_install_new_guide_set_sub_des2')"
                :class="itemCssClass"
                tag="li"
              />
            </ul>
          </s-text>
        </right-wing-item>
        <right-wing-item
          :rightWingTitle="$t('studio.prj_prod.execution_install_new_guide_client_title')"
          numberValue="04"
          class="mt-24"
        >
          <s-text as="span" :role="stextRole" :class="stextClass">
            {{ $t('studio.prj_prod.execution_install_new_guide_client_main_title1') }}
            <ul :class="itemContainerCssClass">
              <safe-html
                :html="$t('studio.prj_prod.execution_pc_client_set_enabled_guide3_1')"
                :class="itemCssClass"
                tag="li"
              />
              <safe-html
                :html="$t('studio.prj_prod.execution_install_new_guide_client_sub_des2')"
                :class="itemCssClass"
                tag="li"
              />
              <li :class="itemCssClass">
                <a
                  href="https://studio-docs.onstove.com/pc/StudioGuide/basicrelease.html#_7-16-1-링크-사용-방"
                  target="_blank"
                  class="inline-block text-xs leading-xs font-regular text-brand-primary underline"
                >
                  ICO converter | STOVE Store Developer Guide
                </a>
              </li>
            </ul>
          </s-text>
          <div class="mt-4">
            <a :href="guideImages1Src" target="_blank">
              <img :src="guideImages1Src" alt="" class="w-full block" />
            </a>
          </div>
          <safe-html
            :html="$t('studio.prj_prod.execution_install_new_guide_client_main_title2')"
            class="text-on-surface-elevation-2 font-medium mt-16"
            tag="p"
          />
          <safe-html
            :html="$t('studio.prj_prod.execution_install_new_guide_client_sub1_des1')"
            tag="p"
          />
          <div class="mt-4">
            <a :href="guideImages2Src" target="_blank">
              <img :src="guideImages2Src" alt="" class="w-full block" />
            </a>
          </div>
          <safe-html
            :html="$t('studio.prj_prod.execution_install_new_guide_client_main_title3')"
            class="text-on-surface-elevation-2 font-medium mt-16"
            tag="p"
          />
          <safe-html
            :html="$t('studio.prj_prod.execution_install_new_guide_client_sub2_des1')"
            tag="p"
          />
          <div class="mt-4">
            <a :href="guideImages3Src" target="_blank">
              <img :src="guideImages3Src" alt="" class="w-full block" />
            </a>
          </div>
        </right-wing-item>
      </div>
    </div>
  </right-wing>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import * as GuideImages1 from '@/assets/images/run-set-up/guide-flatform-pc-01';
import * as GuideImages2 from '@/assets/images/run-set-up/guide-flatform-pc-02';
import * as GuideImages3 from '@/assets/images/run-set-up/guide-flatform-pc-03';
import RightWing from '@/components/app/right-wing/index.vue';
import RightWingItem from '@/components/app/right-wing/item.vue';
import SafeHtml from '@/components/common/safe-html.vue';
import pcGuide from '@/components/launch-setting/pc-guide.vue';
import webGuide from '@/components/launch-setting/web-guide.vue';
import { PLATFORM } from '@/constants/common.const';

defineProps<{
  isShowPcExecutionName: boolean;
  typeGame: string;
  platFormsGuide: string[];
}>();

const { locale } = useI18n();
const itemCssClass =
  'relative pl-16 before:absolute before:top-[.9rem] before:left-4 before:w-4 before:h-4 before:rounded-full before:bg-[currentColor]';
const itemContainerCssClass = 'text-on-surface-elevation-3 font-regular';
const stextRole = 'text4';
const stextClass = 'block font-medium mt-8 text-on-surface-elevation-2';
const guideContainer = ref<HTMLElement | null>(null);

const guideImages1 = ref<Record<string, string>>(GuideImages1);
const guideImages2 = ref<Record<string, string>>(GuideImages2);
const guideImages3 = ref<Record<string, string>>(GuideImages3);

const guideImages1Src = computed(
  () => guideImages1.value[`Guide_${locale.value.replace('-', '_')}`]
);

const guideImages2Src = computed(
  () => guideImages2.value[`Guide_${locale.value.replace('-', '_')}`]
);
const guideImages3Src = computed(
  () => guideImages3.value[`Guide_${locale.value.replace('-', '_')}`]
);
</script>
