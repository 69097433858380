<template>
  <div :id="uuid" class="flex items-center gap-12">
    <p
      class="text-xl leading-xl break-all flex flex-1 items-center"
      :class="
        isActive && adminStep
          ? 'font-bold text-brand-primary'
          : 'font-bold text-on-surface-elevation-2'
      "
    >
      <span
        v-if="adminStep"
        class="mr-8 h-36 w-36 rounded-full flex items-center justify-center text-md font-bold leading-md shrink-0"
        :class="
          isActive
            ? 'text-white bg-brand-primary shadow-[0_.6rem_1.4rem_rgba(0,48,217,.2)]'
            : 'text-gray300 bg-[var(--stds-glob-color-gray60)]'
        "
      >
        {{ adminStep }}
      </span>
      {{ adminName }}
    </p>

    <slot name="button"></slot>

    <button type="button" class="shrink-0 h-24" @click="onOpen()">
      <s-icon
        size="4xl"
        icon="ic-v2-control-arrow-right-line"
        class="text-on-surface-elevation-1 flex"
        :class="{
          'rotate-[-90deg]': isOpenDetail,
          'rotate-90': !isOpenDetail
        }"
      />
    </button>
  </div>
  <div v-show="isOpenDetail" class="mt-24">
    <slot name="content"></slot>
  </div>
</template>
<script setup lang="ts">
import { ref, toRefs } from 'vue';

import { generateUUID } from '@/utils/uuid.util';

const uuid: string = `launch-setting_${generateUUID()}`;

const props = defineProps<{
  adminStep?: string;
  adminName?: string;
  isActive?: boolean;
  errorCase?: boolean;
  hasLaunchOptions?: boolean;
  isOpen?: boolean;
}>();

const { adminName } = toRefs(props);

const isOpenDetail = ref<boolean>(true);

const onOpen = () => {
  isOpenDetail.value = !isOpenDetail.value;
};
const onUnfold = () => {
  isOpenDetail.value = true;
};
defineExpose({
  onOpen: onUnfold
});
</script>
